import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Dots } from 'react-activity';
import "react-activity/dist/Dots.css";
import QRCode from "react-qr-code";

import moment from 'moment';
// utilities
import Functions from '../Utilities/Functions';
import Constants from '../Utilities/Constants';

import AuthController from '../Utilities/Auth';
import withRouter from '../Utilities/withRouter'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import PhoneCountrySelect from '../PhoneCountrySelect';

// import '../../public/assets/css/eventregister.css';

class RegisterEvent extends Component {
    constructor(props) {
        super(props);
        let enableInput = false

        const { eventid, bannerShow } = props.params;

        this.myRef = React.createRef();
        this.state = {
            loading: false,
            errors: {},
            ResErrors: {},
            isRedirect: false,
            sangathanId: null,
            Locations: [],
            country_code: '+91',
            ccode: "+91",
            email: "",
            mobileNo: "",
            remark: "",
            LoactionName: "",
            address: "",
            firstName: "",
            lastName: "",

            eventName: "",
            Eventdetails: {},
            locationDetails: null,
            countries: [],
            stateList: [],
            districtList: [],
            CityList: [],
            BannerShow: bannerShow,
            selectedCity: "",
            selectedCountry: "",
            selectedDistrict: "",
            selectedState: "",

            countryDataCode: "",
            stateCode: "",
            districtCode: "",
            cityCode: "",

            QRCodeValue: "",
            selectedLocation: "",
            postal_code: "",
            area: "",
            areas: [],
            street_address: "",
            addressComponent: "",
            eventId: eventid,
            userId: "",
            defaultAddress: "",
            enableInput: enableInput,
            IsRegister: enableInput,
            token: 'Bearer SECRET',
            IsRegistrationOpen: true,
            BoyChilds: 0,
            GirlChilds: 0,
            ManAdults: 0,
            WomanAdults: 0,
            Pincode: "",
            isNumberRegister: false
        };

    }


    async componentDidMount() {

        // await appendScript('/assets/js/pages/crud/file-upload/image-input.js');

        await this._getUserCred();

        // if (this.state.id !== '') {
        //     // await this._getBranch();
        // } else {
        //     this.setState({ loading: false });
        // }

    }

    _getUserCred = async () => {

        if (this.state.eventId !== '' && this.state.eventId != null && this.state.eventId != undefined) {
            await this.getEvent();
        }


        // const { state, signOut } = this.context;

    }
    getEvent = async () => {
        let urlPath = "event/eventdetails/" + this.state.eventId

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((responseData) => {

                if (responseData.status) {
                    var datares = responseData.data
                    var current = new Date();
                    var RegstartD = new Date(datares.registrationStartDate);
                    var RegendD = new Date(datares.registrationEndDate);
                    if (RegstartD <= current && RegendD >= current) {
                        this.state.IsRegistrationOpen = true;
                    } else {
                        this.state.IsRegistrationOpen = false
                    }
                    this.setState({
                        eventName: datares.eventName,
                        Eventdetails: datares,
                        locationDetails: responseData.location,
                        selectedLocation: responseData.location != null ? responseData.location.place_id : ""
                    });

                    document.title = datares.eventName + "-" + responseData.location.name;
                    //this.onGetCountryList();
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    // this.setState({ loading: false });
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {
                // return this.setState({ isInvitingUser: false })
            });

    }

    reset = async () => {
        this.setState({
            QRCodeValue: "", mobileNo: "", errors: "", mobileNo: "", email: "", firstName: "", lastName: "",
            assignRole: "", area: "", areas: [], Pincode: "", street_address: "", addressComponent: null
        });
    }

    onGetCountryList = async () => {

        let urlPath = "auth/CountryList"

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;

                    let countries = []

                    data.map((c, i) => {
                        countries.push({ label: c.country_name, id: c._id, Code: c.id })
                    })

                    console.log(data)
                    this.setState({ countries: countries, refreshInput: false })

                    if (this.state.selectedCountry != '' && this.state.selectedCountry != null) {
                        this.state.stateCode = countries.filter(s => s.label == this.state.selectedCountry)[0].Code
                        console.log(this.state.stateCode)
                        this.onGetStateList(this.state.stateCode);
                    } else {
                        this.state.selectedCity = ""

                        this.state.selectedDistrict = ""
                        this.state.selectedState = ""
                    }
                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
            });
    }
    onGetStateList = async (data) => {
        console.log(data)
        this.state.countryDataCode = data
        if (data != null && data != undefined && data != '') {

            let urlPath = "auth/StatesList/" + data

            fetch(Constants.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body: Body,
            }).then((response) => response.json())
                .then(async (responseData) => {
                    // // // console.log("RES ",responseData)
                    if (responseData.status) {
                        var data = responseData.response;

                        let stateList = []

                        data.map((c, i) => {
                            stateList.push({ label: c.state_title, id: c._id, Code: c.state_id })
                        })
                        console.log(stateList)
                        this.setState({ stateList: stateList, refreshInput: false })

                        if (this.state.selectedState != '' && this.state.selectedState != null) {
                            this.state.stateCode = stateList.filter(s => s.label == this.state.selectedState)[0].Code
                            console.log(this.state.stateCode)
                            this.onGetDistrictList(this.state.stateCode);
                        } else {
                            this.state.selectedState = ""
                            this.state.selectedDistrict = ""
                            this.state.selectedCity = ""

                        }

                    }
                }).catch(error => {
                    // // console.log("err", error);

                }).finally(final => {
                    //return this.setState({ loading: false })
                });
        }
    }
    onGetDistrictList = async (data) => {
        this.state.stateCode = data

        let urlPath = "auth/DistrictsList/" + data

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let districtList = []

                    data.map((c, i) => {
                        districtList.push({ label: c.district_title, id: c._id, Code: c.districtid })
                    })

                    this.setState({ districtList: districtList, refreshInput: false })

                    if (this.state.selectedDistrict != '' && this.state.selectedDistrict != null) {
                        this.state.districtCode = districtList.filter(s => s.label == this.state.selectedDistrict)[0].Code
                        console.log(this.state.districtCode)
                        this.onGetCitiesList(this.state.districtCode);
                    } else {
                        this.state.selectedDistrict = ""
                        this.state.selectedCity = ""
                    }


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
            });
    }
    onGetCitiesList = async (data) => {
        this.state.districtCode = data
        let urlPath = "auth/CitiesList/" + data

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let CityList = []

                    data.map((c, i) => {
                        CityList.push({ label: c.name, id: c._id, Code: c.id })
                    })
                    if (this.state.selectedCity != '' && this.state.selectedCity != null) {
                        this.state.cityCode = CityList.filter(s => s.label == this.state.selectedCity)[0].Code

                    } else {

                        this.state.selectedCity = ""
                    }
                    this.setState({ CityList: CityList, refreshInput: false })


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }


    numberCheckAPI = async () => {

        // this.setState({ loading : true })
        this.setState({ info: { type: "info", msg: "Looking for user, wait..." }, errors: {}, isNumberVerified: true, loading: false });
        // const { state, signOut } = this.context;
        // await this.setState({ isInvitingUser: true })
        const { navigation } = this.props;
        const { ccode, mobileNo, eventId } = this.state;
        const Body = JSON.stringify({
            'countryCode': ccode,
            eventId: eventId,
            "mobileNo": mobileNo.trim(),
            'client': Constants.AUTH_APP_NAME,
            'client_secret': Constants.AUTH_SECRET,
            'eventId': eventId,

        })

        let urlPath = "auth/check-mobile-eventregistered";
        //let urlPath = "check-mobile-registered";

        // console.log(Constants.AUTH_URL + urlPath, token, Body)
        // this.setState({ loading : false })
        // return

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: Body,
        }).then((response) => response.json())
            .then((responseData) => {
                this.state.IsRegister = false;
                this.state.enableInput = true;
                this.state.firstName = "";
                this.state.lastName = "";
                this.state.userId = "";
                console.log(responseData);
                let errors = {}
                this.state.isNumberRegister = true;
                if (responseData.response != null) {

                    if (responseData.response.user != null) {
                        errors.mobileNo = "User already registered.";
                        var user = responseData.response.user
                        this.state.firstName = user.firstName;
                        this.state.lastName = user.lastName;
                        this.state.userId = user._id;
                        this.state.enableInput = false;
                        this.state.remark = user.remarks;
                        this.state.selectedCity = user.city;
                        this.state.selectedCountry = user.country;
                        this.state.selectedDistrict = user.district;
                        this.state.selectedState = user.state;
                        this.state.area = user.area;
                        this.state.addressComponent = user.street_address;
                        this.state.Pincode = user.Pincode;
                        if (this.state.Pincode != "") {
                            this.onChangePostcode(this.state.Pincode);
                        }

                    }
                    if (responseData.response.event != null) {
                        errors.mobileNo = "User already registered in event.";
                        var event = responseData.response.event
                        // this.state.eventId = event._id;
                        if (event != null) {
                            this.state.QRCodeValue = this.state.userId +
                                "_" + event._id
                        } else {
                            this.state.QRCodeValue = ""
                        }

                        this.state.IsRegister = true;
                        this.state.BoyChilds = event.BoyChilds;
                        this.state.GirlChilds = event.GirlChilds;
                        this.state.ManAdults = event.ManAdults;
                        this.state.WomanAdults = event.WomanAdults;
                    }
                    this.setState({ errors: errors, info: { type: "info", msg: "" }, isNumberVerified: false, loading: false });
                }
                else

                    if (responseData.status && responseData.auth) {

                        errors.mobileNo = responseData.error

                        this.setState({ errors: errors, info: { type: "info", msg: "" }, isNumberVerified: false, loading: false });
                    }
                    else if (!responseData.status && responseData.error) {
                        //errors.mobileNo = responseData.error
                        if (responseData.error == "User not found.") {
                            this.state.isNumberRegister = false;
                        }
                        this.setState({ errors: errors, info: { type: "info", msg: "" }, isNumberVerified: false, loading: false });
                        this.setState({ loading: false });
                    }
                    else if (responseData.error && typeof responseData.error != "object") {

                        this.setState({ info: { type: "success", msg: "User is valid" }, errors: {}, isNumberVerified: true, loading: false });


                    } else if (typeof responseData.error === "object") {

                        alert(
                            'Error!  \n\n ' + responseData.error.length > 0 ? responseData.error[0].message : 'Something went wrong, Please try again after sometime.'
                        )
                        // this.setState({ loading : false })
                        let errors = {}

                        errors.mobileNo = "Something went wrong.";

                        this.setState({ errors: errors, isNumberVerified: false, loading: false });
                    } else {

                        alert(
                            'Oops! \n\n Something went wrong, Please try again after sometime.'
                        )

                        let errors = {}

                        errors.mobileNo = "Something went wrong.";

                        this.setState({ errors: errors, isNumberVerified: false, loading: false });
                    }

            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                let errors = {}

                errors.mobileNo = "Something went wrong.";

                this.setState({ errors: errors, isNumberVerified: false, loading: false });

            }).finally(final => {

            });
    }



    checkMobileNumberIsRegistrered = (event) => {
        const inputValue = event.target.value;
        const regex = /^\d{0,10}$/; // Regex to match exactly 10 digits

        if (regex.test(inputValue) && inputValue.length == 10) {

            this.numberCheckAPI()

        } else {
            this.setState({ isNumberVerified: false })
        }

    };
    handleNumberChange = (event) => {
        const inputValue = event.target.value;
        const regex = /^\d{0,10}$/; // Regex to match exactly 10 digits

        if (regex.test(inputValue)) {
            // Valid input: 10 digits without spaces
            // Handle the input or set the state accordingly
            // For example:
            // console.log("N",inputValue,inputValue.length)
            this.setState({ mobileNo: inputValue });
        } else {
            // // // console.log("E",inputValue)
            // Invalid input
            // You can show an error message or prevent further action
        }
    };


    /**
     *
     * @param null
     * @description This function validate inputs
     * @returns boolean
     */
    handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (this.state.firstName?.trim() === "") {
            formIsValid = false;
            errors.firstName = "Please enter first name";
        }

        if (this.state.lastName?.trim() === "") {
            formIsValid = false;
            errors.lastName = "Please enter last name";
        }
        const regexPin = /^\d{0,5}$/;
        if (this.state.Pincode?.trim() === "") {

            formIsValid = false;
            errors.Pincode = "Please enter Pincode";

        } else if (regexPin.test(this.state.Pincode)) {
            formIsValid = false;
            errors.Pincode = "Please enter 6 digit Pincode";
        }
        if (this.state.selectedCity?.trim() === "") {
            formIsValid = false;
            errors.Pincode = "Please enter correct Pincode";
        }



        // if (this.state.ccode?.trim() === "") {
        //     formIsValid = false;
        //     errors.ccode = "Please enter country code";
        // }

        // if (this.state.email?.trim() === "") {
        //     formIsValid = false;
        //     errors.email = "Please enter email address";
        // }

        if (this.state.mobileNo?.trim() === "") {
            formIsValid = false;
            errors.mobileNo = "Please enter mobile number";
        }

        console.log(errors);


        this.setState({ errors: errors });

        return formIsValid;
    }


    onUserInvite = async (e) => {
        console.log(this.handleValidation())
        if (!this.handleValidation()) {
            // // console.log("FAILED")

            return false
        }
        e.preventDefault()
        if (this.state.isNumberRegister == false) {
            const { ccode, BoyChilds, GirlChilds, ManAdults, WomanAdults, selectedCity, selectedDistrict, selectedState, selectedCountry, selectedVillage, cityCode, districtCode, stateCode, countryDataCode,
                mobileNo, firstName, lastName, Pincode, selectedLocation, area, addressComponent, city, remark, userId, eventId, street_address, token } = this.state;
            const Body = JSON.stringify({
                // sangathanId: selectedSangathan._id,
                "firstName": firstName,
                "lastName": lastName,
                "mobileNo": mobileNo.trim(),
                "remarks": remark || '',
                "location": selectedLocation,
                "street_address": addressComponent,
                'countryCode': ccode,
                'client': Constants.AUTH_APP_NAME,
                'client_secret': Constants.AUTH_SECRET,
                'city': selectedCity || '',
                'district': selectedDistrict || '',
                'state': selectedState || '',
                'Pincode': Pincode || '',
                'country': selectedCountry || '',
                'cityCode': cityCode || '',
                'area': area || '',
                'districtCode': districtCode || '',
                'stateCode': stateCode || '',
                'countryDataCode': countryDataCode || '',
                // '_id':userId||'',

            });
            let urlPath = "eventUser";

            fetch(Constants.AUTH_URL + urlPath, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: Body,
            }).then((response) => response.json())
                .then((responseData) => {
                    console.log(responseData);
                    if (responseData.status && responseData.response) {

                        this.state.userId = responseData.response.user._id

                        setTimeout(() => {
                            this.UserEventCreate(e);
                        }, 100)

                    } else if (!responseData.status && responseData.error) {
                        alert(
                            responseData?.error || "Error")
                        this.setState({ loading: false });
                    } else {
                        alert(
                            'Something went wrong, Please try again after sometime.'
                        )
                        this.setState({ loading: false });
                    }
                }).catch(error => {
                    console.log("err", error);
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    this.setState({ loading: false });
                }).finally(final => {
                    // return this.setState({ isInvitingUser: false })
                });
        } else {

            const { ccode, BoyChilds, GirlChilds, ManAdults, WomanAdults, selectedCity, selectedDistrict, selectedState, selectedCountry, selectedVillage, cityCode, districtCode, stateCode, countryDataCode,
                mobileNo, firstName, lastName, Pincode, selectedLocation, area, addressComponent, city, remark, userId, eventId, street_address, token } = this.state;
            const Body = JSON.stringify({
                // sangathanId: selectedSangathan._id,
                "firstName": firstName,
                "lastName": lastName,
                "remarks": remark || '',
                "street_address": addressComponent,
                'countryCode': ccode,
                'client': Constants.AUTH_APP_NAME,
                'client_secret': Constants.AUTH_SECRET,
                'city': selectedCity || '',
                'district': selectedDistrict || '',
                'state': selectedState || '',
                'Pincode': Pincode || '',
                'country': selectedCountry || '',
                'area': area || ''

            });
            let urlPath = "userPincodeupdate/" + userId;

            fetch(Constants.AUTH_URL + urlPath, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: Body,
            }).then((response) => response.json())
                .then((responseData) => {
                    console.log(responseData);
                    if (responseData.status) {


                        setTimeout(() => {
                            this.UserEventCreate(e);
                        }, 100)

                    } else if (!responseData.status && responseData.error) {
                        alert(
                            responseData?.error || "Error")
                        this.setState({ loading: false });
                    } else {
                        alert(
                            'Something went wrong, Please try again after sometime.'
                        )
                        this.setState({ loading: false });
                    }
                }).catch(error => {
                    console.log("err", error);
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    this.setState({ loading: false });
                }).finally(final => {
                    // return this.setState({ isInvitingUser: false })
                });
        }
    }

    UserEventCreate = async (e) => {
        const { ccode, BoyChilds, GirlChilds, ManAdults, WomanAdults, selectedCity, selectedDistrict, selectedState, selectedCountry, selectedVillage, cityCode, districtCode, stateCode, countryDataCode,
            mobileNo, firstName, lastName, Pincode, token, area, addressComponent, selectedLocation, city, remark, userId, eventId, street_address } = this.state;
        const Body = JSON.stringify({
            // sangathanId: selectedSangathan._id,
            BoyChilds: BoyChilds,
            GirlChilds: GirlChilds,
            ManAdults: ManAdults,
            WomanAdults: WomanAdults,
            selectedLocation: selectedLocation,
            // role: assignRole,
            userId: userId,
            eventId: eventId,
            "firstName": firstName,
            "lastName": lastName,
            "mobileNo": mobileNo.trim(),
            "Pincode": Pincode,
            // "remarks": remark || '',
            "addressComponent": addressComponent || '',
            "location": selectedLocation,
            'countryCode': ccode,
            'client': Constants.AUTH_APP_NAME,
            'client_secret': Constants.AUTH_SECRET,
            'city': selectedCity || '',
            'district': selectedDistrict || '',
            'state': selectedState || '',
            'country': selectedCountry || '',
            'cityCode': cityCode || '',
            'districtCode': districtCode || '',
            'stateCode': stateCode || '',
            'area': area || '',
            'countryDataCode': countryDataCode || '',

        });
        let urlPath = "event/registerEvent";
        console.log(Constants.VUF_APP_API + urlPath, token, Body)
        // return

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: Body,
        }).then((response) => response.json())
            .then((responseData) => {
                console.log(responseData);
                if (responseData.status && responseData.response) {
                    alert(
                        "Event Register Successfully.")

                    this.setState({
                        email: "", firstName: "", lastName: "", assignRole: "", QRCodeValue: responseData.response.userId +
                            "_" + responseData.response.eventId
                    });
                } else if (!responseData.status && "auth" in responseData) {
                    AuthController.Logout();
                } else if (!responseData.status && responseData.error) {
                    alert(
                        responseData?.error || "Error")
                    this.setState({ loading: false });
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    this.setState({ loading: false });
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                this.setState({ loading: false });
            }).finally(final => {
                // return this.setState({ isInvitingUser: false })
            });
    }
    onChangePostcode = (e) => {
        console.log(e)
        //let urlPath = "https://api.postalpincode.in/pincode/" + e;

        if (e != null && e != "") {
            let urlPath = "auth/PostalCode/" + e;

            fetch(Constants.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then((response) => response.json())
                .then((responseData) => {
                    console.log(responseData);

                    if (responseData.status) {

                        if (responseData.response[0].PostOffice != null && responseData.response[0].PostOffice.length > 0) {
                            var data = responseData.response[0].PostOffice[0];
                            this.state.selectedCountry = data.Country;
                            this.state.selectedState = data.State;
                            this.state.selectedDistrict = data.District;
                            this.state.selectedCity = data.Block;
                            this.state.street_address = data.Country + ", " + data.State + ", " + data.District + ", " + data.Block
                            let areas = []
                            responseData.response[0].PostOffice.map((c, i) => {
                                areas.push({ label: c.Name, id: c.Name, Code: c.Name })
                            })
                            console.log(data)
                            this.setState({ areas: areas })

                            //this.onGetCountryList();
                        } else {
                            this.setState({ selectedCountry: "", selectedState: "", selectedDistrict: "", selectedCity: "", area: "", areas: [] })

                            let errors = {};
                            errors.Pincode = "Please enter correct Pincode";
                            this.setState({ errors: errors });

                        }
                    } {

                    }

                    this.setState({ loading: false });

                }).catch(error => {
                    console.log("err", error);
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    this.setState({ loading: false });
                });
        } else {
            this.setState({ selectedCountry: "", selectedState: "", selectedDistrict: "", selectedCity: "" })
        }
    }

    onSelectLocation = (e) => {
        let location = e.target.value

        const foundItem = this.state.Locations.find(item => item.place_id == location);
        //this.state.QRCodeValue = location;
        // console.log("LOC ",foundItem,this.getFormattedAddressComponentData(foundItem?.addressComponent || null) )
        this.setState({ selectedLocation: location, refreshInput: true })
        this.state.selectedCity = foundItem.city;
        this.state.selectedCountry = foundItem.country;
        this.state.selectedDistrict = foundItem.district;
        this.state.selectedState = foundItem.state;
        // this.onGetCountryList();
        setTimeout(() => {
            this.setState({ refreshInput: false })
        }, 100)
        // setResult(foundItem || null);
    }


    redirect() {
        if (this.state.isRedirect) {
            return <Navigate to={'/'} />
        }
    }

    render() {

        const { dropdownData } = this.state;
        let divImage = this.state.Eventdetails.eventBannerImage != "null" && this.state.BannerShow == "false" ? "https://api.app.vishvumiyafoundation.org/" + this.state.Eventdetails.eventBannerImage : "../../assets/images/VUF_temple.jpg";
        // console.log(this.state.loading)
        const formatDate = (stdate) => {
            const date = new Date(stdate); // 8th September 2024 (Month is 0-based, so 8 represents September)
            const options = { day: "numeric", month: "long" };
            return date.toLocaleDateString("en-GB", options); // "8 September"
        };
        return (

            this.state.loading
                ?
                <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
                :
                <>
                    <div>

                        {/* Header with Logo and Event Title */}
                        <header className="header">
                            <img src="../../assets/images/vuflogo.png" alt="Vishv Umiya Logo" className="logo" />
                            <h1>Event Registration</h1>
                        </header>

                        {/* Banner with Background Image and Event Details */}
                        <div className="banner" style={{ backgroundImage: 'url(' + divImage + ')' }}>

                            {this.state.BannerShow == "true" &&
                                <div className=''>
                                    <h2>{this.state.eventName}</h2>
                                    <p>{formatDate(this.state.Eventdetails.eventStartDate)}
                                        {/* to {moment(this.state.Eventdetails.eventEndDate).format("DD MMM YYYY")}  */}
                                    </p>
                                    <p>Venue: {this.state.Eventdetails != null && this.state.Eventdetails.Address != null && this.state.Eventdetails.Address != undefined ? <span> {this.state.Eventdetails.Address} </span> : <span> Country - {this.state.Eventdetails.country}. State - {this.state.Eventdetails.state}. City - {this.state.Eventdetails.city} </span>}
                                        {/* {this.state.locationDetails != null && <span >{this.state.locationDetails.name}</span>}  */}
                                    </p>
                                </div>

                            }
                        </div>
                        {/* <div  style={{ backgroundImage: `url("../assets/images/vuf-border.png") `,backgroundRepeat:'repeat' }}>
                        </div> */}
                        <div class="temple-border"></div>
                        {/* <img src="../assets/images/vuf-border.png" style={{backgroundRepeat:'repeat-x',backgroundSize:'auto 40px'}} alt="border Image" class="temple-border" /> */}
                        {/* Registration Form */}
                        <div className="container">
                            {this.state.IsRegistrationOpen == true ?
                                <div className="col-md-12">
                                    <br />
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <label>Mobile Number / મોબાઇલ નંબર:</label>
                                                <div className='row'>
                                                    <div className='col-3  col-md-2'>
                                                        {/* <div className="">
                                                            <div className="phone-country-form-control-input">
                                                                <PhoneCountrySelect name="ccode"  className="form-control p-1 form-control-solid" placeholder="+91" onChange={(event) => {Functions.onChange(event, this);console.log(event)}}   />
                                                                
                                                            </div>
                                                        </div> */}

                                                        <input type="text" name="ccode" disabled="true" className="form-control p-1 form-control-solid" placeholder="+91" onChange={(event) => Functions.onChange(event, this)} value={this.state.ccode} />
                                                        <span className="form-text text-danger">{this.state.errors?.ccode}</span>
                                                    </div>
                                                    <div className='col-9 col-md-10'>
                                                        <input type="text" name="mobileNo" disabled={this.state.QRCodeValue != ""} className="form-control form-control-solid" placeholder="Mobile number" onBlur={(event) => this.checkMobileNumberIsRegistrered(event)} onChange={(event) => this.handleNumberChange(event)} value={this.state.mobileNo} />
                                                        <span className="form-text text-danger">{this.state.errors?.mobileNo}</span>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        {this.state.QRCodeValue == "" &&
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label>First Name / નામ:</label>
                                                    <input type="text" name="firstName" disabled={!this.state.enableInput} className="form-control form-control-solid" placeholder="Enter first name" onChange={(event) => Functions.onChange(event, this)} value={this.state.firstName} />
                                                    <span className="form-text text-danger">{this.state.errors?.firstName}</span>
                                                </div>

                                                <div className="col-md-6">
                                                    <label>Last Name / અટક:</label>
                                                    <input type="text" name="lastName" disabled={!this.state.enableInput} className="form-control form-control-solid" placeholder="Enter last name" onChange={(event) => Functions.onChange(event, this)} value={this.state.lastName} />
                                                    <span className="form-text text-danger">{this.state.errors?.lastName}</span>
                                                </div>

                                            </div>
                                        }  {this.state.QRCodeValue == "" &&
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label>Pincode / પિનકોડ: </label>
                                                    <input type="text" name="Pincode" onBlur={(event) => { this.onChangePostcode(event.target.value) }}
                                                        className="form-control form-control-solid" placeholder="Pincode" onChange={(event) => Functions.onChange(event, this)} value={this.state.Pincode} />
                                                    <span className="form-text text-danger">{this.state.errors?.Pincode}</span>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Country / દેશ, State / રાજ્ય, District / જિલ્લો, City / શહેર </label>

                                                    <input type="text" name="street_address" disabled="true" className="form-control form-control-solid" value={this.state.street_address} />
                                                    {/* <Autocomplete
                                                        disablePortal
                                                        disabled={this.state.enableInput && this.state.countries.length > 0 ? false : true}
                                                        id="combo-box-demo"
                                                        value={this.state.selectedCountry}
                                                        options={this.state.countries}
                                                        onChange={(e, v, r, d) => {
                                                            // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                            if (r == 'selectOption') {
                                                                this.setState({ selectedCountry: v.label, country: v.label })
                                                                this.onGetStateList(v.Code)
                                                                //this.onSelectCountry(v.label)
                                                            } else if (r == 'clear') {
                                                                this.setState({ selectedState: "" })
                                                                this.setState({ selectedDistrict: "" })
                                                                this.setState({ selectedCity: "" })
                                                                this.setState({ selectedCountry: "" })
                                                            }
                                                        }}

                                                        renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                    /> */}
                                                </div>
                                                <div className="col-md-2">
                                                    <label>Area / વિસ્તાર (Optional): </label>
                                                    <Autocomplete
                                                        disablePortal
                                                        disabled={this.state.areas.length > 0 ? false : true}
                                                        id="combo-box-demo"
                                                        value={this.state.area}
                                                        options={this.state.areas}
                                                        onChange={(e, v, r, d) => {
                                                            // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                            if (r == 'selectOption') {
                                                                this.setState({ area: v.label })

                                                                //this.onSelectCountry(v.label)
                                                            } else if (r == 'clear') {
                                                                this.setState({ area: "" })

                                                            }
                                                        }}

                                                        renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                    />
                                                </div>

                                            </div>
                                        }
                                        {this.state.QRCodeValue == "" &&
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label>Address / સરનામું (Optional):</label>
                                                    <textarea disabled={!this.state.enableInput} type="text" name="addressComponent" className="form-control form-control-solid" placeholder="Enter Address" onChange={(event) => Functions.onChange(event, this)}>
                                                        {this.state.addressComponent}
                                                    </textarea>
                                                    <span className="form-text text-danger">{this.state.errors?.addressComponent}</span>
                                                </div>
                                                {/* 
                                                <div className="col-md-3">
                                                    <label>State / રાજ્ય:</label>
                                                    <input type="text" name="selectedState" disabled={!this.state.selectedState} className="form-control form-control-solid" placeholder="Enter State" value={this.state.selectedState} />
                                                </div>
                                                <div className="col-md-3">
                                                    <label>District / જિલ્લો:</label>
                                                    <input type="text" name="selectedDistrict" disabled={!this.state.selectedDistrict} className="form-control form-control-solid" placeholder="Enter District" value={this.state.selectedDistrict} />
                                                </div>
                                                <div className="col-md-3">
                                                    <label>City / શહેર:</label>
                                                    <input type="text" name="selectedCity" disabled={!this.state.selectedCity} className="form-control form-control-solid" placeholder="Enter City" value={this.state.selectedCity} />
                                                    <span className="form-text text-danger">{this.state.errors?.selectedCity}</span>
                                                </div> */}



                                            </div>
                                        }  {this.state.QRCodeValue == "" &&
                                            <div className='row' style={{ display: "none" }}>
                                                <div className='col-md-12 '>
                                                    <label>Famliy Member</label>
                                                    <div className='row'>
                                                        <div className='col-6 col-lg-3'> <label className='mt-3 '>Man Adult:</label><br />  <input type="number" min="0" max="10" name="ManAdults" className="form-control " onChange={(event) => { this.setState({ ManAdults: event.target.value }); Functions.onChange(event, this) }} value={this.state.ManAdults} /></div>
                                                        <div className='col-6 col-lg-3'> <label className='mt-3 '>Woman Adult:</label><br /> <input type="number" min="0" max="10" name="WomanAdults" className="form-control " onChange={(event) => { this.setState({ WomanAdults: event.target.value }); Functions.onChange(event, this) }} value={this.state.WomanAdults} /></div>
                                                        <div className='col-6 col-lg-3'>  <label className='mt-3'>Boy Child:</label><br />  <input type="number" min="0" max="10" name="BoyChilds" className="form-control " onChange={(event) => { this.setState({ BoyChilds: event.target.value }); Functions.onChange(event, this) }} value={this.state.BoyChilds} /></div>
                                                        <div className='col-6 col-lg-3'><label className='mt-3 '>Girl Child:</label><br />  <input type="number" min="0" max="10" name="GirlChilds" className="form-control " onChange={(event) => { this.setState({ GirlChilds: event.target.value }); Functions.onChange(event, this) }} value={this.state.GirlChilds} /></div>
                                                        <span className="form-text text-danger">{this.state.errors?.eventDescription}</span>
                                                    </div>
                                                </div>

                                            </div>
                                        }
                                        {this.state.QRCodeValue != "" &&
                                            <div className='text-center  mt-2'>
                                                <span className='h5'>Thank you for Registration!!</span> <br />
                                                <h1>Scan Registration OR</h1>
                                                <QRCode
                                                    // size={256}
                                                    //style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={this.state.QRCodeValue}
                                                // viewBox={`0 0 256 256`}
                                                />
                                                <br />
                                                <br />
                                                <button type="button" className="btn btn-primary mr-2" onClick={(event) => this.reset()} >Reset</button>
                                            </div>
                                        }

                                    </div>
                                </div>

                                : ""

                            }
                            {this.state.IsRegistrationOpen == true && this.state.QRCodeValue == "" ?
                                <div className="text-center m-5" style={{ justifyContent: 'center', alignItems: 'center' }}>

                                    <button type="button" className="btn btn-primary mr-2" onClick={(event) => this.onUserInvite(event)}>Register Now</button>

                                </div>
                                : ""
                            }
                            {this.state.IsRegistrationOpen == false ?
                                <div className="card-body row">

                                    <label>Registration Close</label>

                                </div>
                                : ""
                            }
                        </div>

                        {/* Footer with Red Background and Footer Image */}
                        {/* <div className='temple-image'>
                            <img src="../assets/images/temple.jpg" alt="Temple Image" class="" />
                        </div> */}
                        <div style={{ marginLeft: "80%" }}>
                            <img src="../../assets/images/temple.jpg" alt="Temple Image" class="temple-image" />
                        </div>
                        <div class="footer">

                            <p class="iso">ISO 9001:2015 Approval Number: 00044220</p>
                            <p class="copyright">Copyright © 2024 Vishv Umiya Foundation. All Rights Reserved.</p>
                        </div>


                    </div>
                </>
        );
    }
}

export default withRouter(RegisterEvent);
