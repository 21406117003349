import React, { Component } from 'react';

class SubHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: '',
            subTitle: [],
            Hloading: true,
        }
       
    }
    componentDidMount() {
      
        setTimeout(() => {
            this.getBreadcumb()
        });
    }

    async componentWillReceiveProps() {
        this.setState({ Hloading: true })
        setTimeout(() => {
            this.getBreadcumb()
        });
    }

    // componentWillUnmount() {
    //     localStorage.removeItem('heading');
    // }

    getBreadcumb = async () => {
        try {
            let breadcrumb = await localStorage.getItem("heading");
            if (breadcrumb) {
                breadcrumb = JSON.parse(breadcrumb)
                this.setState({ title: breadcrumb.title, subTitle: breadcrumb.subTitle, Hloading: false })
                // console.log('will receive');
            } else {
                await this.setState({ title: '', subTitle: [], Hloading: false });
            }
        }
        catch (e) {
            console.log("subheader error:", e);
        }
    }

    render() {

        return (
            <>
                {/* <!--begin::Subheader--> */}
                <div  className=" header-fixed subheader py-2 py-lg-4 subheader-solid">
                {/* <div className="subheader py-2 py-lg-4 subheader-solid" id="kt_subheader"> */}
                    <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                        {/* <!--begin::Info--> */}
                        {
                            this.state.Hloading ? '' :

                                <div className="d-flex align-items-center flex-wrap mr-2">
                                    {/* <!--begin::Page Title--> */}
                                    <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">{this.state.title}</h5>
                                    {/* <!--end::Page Title--> */}
                                    {/* <!--begin::Actions--> */}
                                    {
                                        this.state.subTitle.map((sub, index) => {
                                            return (
                                                <SubTitle key={sub} subTitle={sub} />
                                            )
                                        })
                                    }

                                    {/* <!--end::Actions--> */}
                                </div>
                        }
                        {/* <!--end::Info--> */}

                    </div>
                </div>
                {/* <!--end::Subheader--> */}
            </>
        );
    }
}

function SubTitle(props) {
    return (
        <>
            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>
            <span className="text-muted font-weight-bold mr-4">{props.subTitle}</span>
        </>
    )

}

export default SubHeader;