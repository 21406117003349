import React, { Component, Suspense, lazy } from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation  } from 'react-router-dom';
// const Base = lazy(() => import('./Routes/base'));
import Base from './Routes/base';
import RegisterEvent from './Pages/RegisterEvent';
import {  } from 'react-router-dom';
import Event from './Pages/Event';
import EventDashboard from './Pages/EventDashboard';

import RegisterVisitor from './Pages/RegisterVisitor'
import EventRegisterView from './Pages/EventRegisterView'

import QrScan from './Pages/QrScan'
import EventAttend from './Pages/EventAttend';
import EventUsers from './Pages/EventUsers';
const Loginroute = lazy(() => import('./Routes/loginRoute'));

class App extends Component {
  constructor(props) {
    super(props)
    const { location } = this.props;

    this.state = {
      isLogin: false,
      loading: true,
      location:location,
      allowedRoutes: [
        "/",
        "/register/",
        "/register/:location",
        "/event-registration/:userId",
        "/qr-scan",
        "/EventRegister/:eventid/:bannerShow",
        "/dashboard",
        "/EventUsers/:id",
        "/EventAttendant/:id"
      ]
    }
  }
  

  async componentDidMount() {
    
    let user = await localStorage.getItem('user');
    user = await JSON.parse(user)
    if (user) {
      await this.setState({ isLogin: true })
    }else{
      const currentUrl = window.location.href;  // Full URL
      const pathname = window.location.pathname;  // Pathname part of the URL
    
      const isRouteAllowed = this.state.allowedRoutes.some(route => this.routeToRegex(route).test(pathname));
      console.log("route: " +isRouteAllowed);
      if(isRouteAllowed==false){
        window.location = '/';
      }
    }
    console.log("App",this.state.isLogin)
  }

   routeToRegex = (route) => {
    return new RegExp(
      "^" +
      route
        .replace(/:\w+/g, "[^/]+") // Replace :param with a regex pattern that matches any segment
        .replace(/\//g, "\\/") + // Escape forward slashes
      "$"
    );
  };

  render() {

    return (
      <Router forceRefresh={true}>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
          {
            this.state.isLogin ?
              <Route path="/*" name='App' element={<Base />}></Route>
              :
              <>
                <Route path="/" name='App' exact element={<Loginroute />}></Route>

                <Route path="/register/" name='App' exact element={<RegisterVisitor />}></Route>
                <Route path="/register/:location" name='App' exact element={<RegisterVisitor />}></Route>
                <Route path="/event-registration/:userId" name='App' exact element={<EventRegisterView />}></Route>
                <Route path="/qr-scan" name='App' exact element={<QrScan />}></Route>

                <Route path="/EventRegister/:eventid/:bannerShow"  name='App' element={<RegisterEvent />}></Route>
                <Route path="/dashboard" name='App' element={<EventDashboard />}></Route>
                <Route path="/EventUsers/:id" exact  name='App' element={<EventUsers />}></Route>
										
                <Route path="/Event"  name='App' element={<Event />}></Route>
                <Route path="/EventAttendant/:id" name='App' element={<EventAttend />}></Route>
                {/* <Route path="/signup" name='Signup' element={<Signup />}></Route> */}
              </>
          }
          </Routes>
        </Suspense>
      </Router>
    );

  }

}

export default App;
