import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Dots } from 'react-activity';
import "react-activity/dist/Dots.css";
import QRCode from "react-qr-code";

import moment from 'moment';
import { Link } from 'react-router-dom';
// utilities
import Constants from '../Utilities/Constants';

import withRouter from '../Utilities/withRouter'
import '../../node_modules/react-date-range/dist/styles.css'; // main style file
import '../../node_modules/react-date-range/dist/theme/default.css'; // theme css file
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DateRangePicker } from 'react-date-range';
import '../../node_modules/react-date-range/dist/styles.css'; // main style file
import '../../node_modules/react-date-range/dist/theme/default.css'; // theme css file
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file


class EventUsers extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        const { id } = props.params;
        this.state = {
            loading: false,
            errors: {},
            ResErrors: {},
            isRedirect: false,
            eventId: id != null ? id : "65c5dfabce585f6d12c398d2",
            Eventdetails: {},
            locations: [],
            copy: [],
            EventStatus: "",
            RegistrationStatus: "",

            countries: [],
            stateList: [],
            districtList: [],
            cityList: [],
            VillageList: [],

            selectedCity: "",
            selectedCountry: "",
            selectedVillage: "",
            selectedDistrict: "",
            selectedState: "",

            cityCode: "",
            districtCode: "",
            stateCode: "",
            countryDataCode: "",
            villageCode: "",

            ShowFilters: false,
            ShowEventDetails: true,
            totalUsers: 0,

            selectionRange: {
                startDate: new Date(moment().subtract(30, 'days')),
                endDate: new Date(moment()),
                key: 'selection',
            },
            userList: [],
            Date: "",
            showDatePicker: false,

        };

    }


    async componentDidMount() {

        // await appendScript('/assets/js/pages/crud/file-upload/image-input.js');
        let heading = {
            title: 'Event',
            subTitle: ['Event User']
        };
        localStorage.setItem('heading', JSON.stringify(heading));
        this.getEvent()
        
       
        // Deprecated 
        // this.getLocations()

        // if (this.state.id !== '') {
        //     // await this._getBranch();
        // } else {
        //     this.setState({ loading: false });
        // }

    }

    exportToExcel = (data) => {

        var fileName = "EventUser"
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `${fileName}.xlsx`);
    };

    getLocations = () => {

        let urlPath = "DynamicPage/entry-locations/"

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((responseData) => {

                // console.log(responseData);

                if (responseData.status) {
                    this.setState({ locations: responseData.data, });
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    // this.setState({ loading: false });
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {
                // return this.setState({ isInvitingUser: false })
            });

    }
    onGetCountryList = async () => {
        await this.setState({ loading: true })

        let urlPath = "auth/CountryList"

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;

                    let countries = []

                    data.map((c, i) => {
                        countries.push({ label: c.country_name, id: c._id, Code: c.id })
                    })
                    if(this.state.userList.length>0){
                      
                        var countr=this.state.userList.map(function(a) {return a.country;}).filter((item, i, arr) => arr.findIndex((t) => t=== item) === i);
                        console.log(countr)
                       
                        countries= countries.filter(s=>countr.filter(a=> a!=undefined && a==s.label).length>0 )
                        console.log(countries)
                    }
                    console.log(data)
                    this.setState({ countries: countries, refreshInput: false })

                    this.state.selectedCity = ""
                    this.state.selectedVillage = ""
                    this.state.selectedDistrict = ""
                    this.state.selectedState = ""

                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }
    onGetStateList = async (data) => {
        console.log(data)
        this.state.countryDataCode = data
        if (data != null && data != undefined && data != '') {

            let urlPath = "auth/StatesList/" + data

            fetch(Constants.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body: Body,
            }).then((response) => response.json())
                .then(async (responseData) => {
                    // // // console.log("RES ",responseData)
                    if (responseData.status) {
                        var data = responseData.response;

                        let stateList = []

                        data.map((c, i) => {
                            stateList.push({ label: c.state_title, id: c._id, Code: c.state_id })
                        })
                        if(this.state.selectedState=="" || this.state.selectedState==null){
                      
                            var seledlis=this.state.userList.map(function(a) {return a.state;}).filter((item, i, arr) => arr.findIndex((t) => t=== item) === i);
                            console.log(seledlis)
                           
                            stateList= stateList.filter(s=>seledlis.filter(a=> a!=undefined && a==s.label).length>0 )
                            console.log(stateList)
                        }
                        console.log(stateList)
                        this.setState({ stateList: stateList, refreshInput: false })

                        this.state.selectedState = ""
                        this.state.selectedDistrict = ""
                        this.state.selectedCity = ""
                        this.state.selectedVillage = ""



                    }
                }).catch(error => {
                    // // console.log("err", error);

                }).finally(final => {
                    //return this.setState({ loading: false })
                });
        }
    }
    onGetDistrictList = async (data) => {
        this.state.stateCode = data

        let urlPath = "auth/DistrictsList/" + data

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let districtList = []

                    data.map((c, i) => {
                        districtList.push({ label: c.district_title, id: c._id, Code: c.districtid })
                    })
                    if(this.state.selectedDistrict=="" || this.state.selectedDistrict==null){
                      
                        var seledlis=this.state.userList.map(function(a) {return a.district;}).filter((item, i, arr) => arr.findIndex((t) => t=== item) === i);
                        console.log(seledlis)
                       
                        districtList= districtList.filter(s=>seledlis.filter(a=> a!=undefined && a==s.label).length>0 )
                        console.log(districtList)
                    }
                    this.setState({ districtList: districtList, refreshInput: false })


                    this.state.selectedDistrict = ""
                    this.state.selectedCity = ""
                    this.state.selectedVillage = ""



                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }
    onGetCitiesList = async (data) => {
        this.state.districtCode = data
        let urlPath = "auth/CitiesList/" + data

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let cityList = []

                    data.map((c, i) => {
                        cityList.push({ label: c.name, id: c._id, Code: c.id })
                    })
                    if(this.state.selectedCity=="" || this.state.selectedCity==null){
                      
                        var seledlis=this.state.userList.map(function(a) {return a.city;}).filter((item, i, arr) => arr.findIndex((t) => t=== item) === i);
                        console.log(seledlis)
                       
                        cityList= cityList.filter(s=>seledlis.filter(a=> a!=undefined && a==s.label).length>0 )
                        console.log(cityList)
                    }
                    this.setState({ cityList: cityList, refreshInput: false })


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }
    getEvent = async () => {
        let urlPath = "event/eventdetails/" + this.state.eventId

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((responseData) => {

                if (responseData.status) {
                    var datares = responseData.data
                    console.log(datares.name);
                    this.setState({
                        Eventdetails: datares
                    });
                    var current = new Date();
                    var EventstartD = new Date(this.state.Eventdetails.eventStartDate);
                    var EventendD = new Date(this.state.Eventdetails.eventEndDate);

                    // If both start and end dates are in the past
                    if (EventstartD < current && EventendD < current) {
                        this.setState({ EventStatus: "Expired" });
                    }
                    // If the current date is between the start and end dates (event is ongoing)
                    else if (EventstartD <= current && EventendD  >= current ) {
                        this.setState({ EventStatus: "Running" });
                    }
                    // If both start and end dates are in the future (event is upcoming)
                    else if (EventstartD > current && EventendD > current) {
                        this.setState({ EventStatus: "UpComing" });
                    }
                   
                    var RegstartD = new Date(this.state.Eventdetails.registrationStartDate);
                    var RegendD = new Date(this.state.Eventdetails.registrationEndDate);
                    
                    if(RegstartD!=null && RegendD!=null){
                        this.state.selectionRange.startDate=RegstartD;
                        this.state.selectionRange.endDate=RegendD
                    }

                    this.getusers(true,this.state.selectionRange.startDate,this.state.selectionRange.endDate,"","","","")
                    // If both start and end dates are in the past
                    if (RegstartD < current && RegendD < current) {
                        this.setState({ RegistrationStatus: "Close" });
                    }
                    // If current date is between start and end dates
                    else if (RegstartD <= current && RegendD >= current) {
                        this.setState({ RegistrationStatus: "Open" });
                    }
                    // If both start and end dates are in the future
                    else if (RegstartD > current && RegendD > current) {
                        this.setState({ RegistrationStatus: "UpComing" });
                    }

                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    // this.setState({ loading: false });
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {
                // return this.setState({ isInvitingUser: false })
            });

    }

    getusers = (loading, startDate,endDate, Country, state, district, city) => {
        // getReportDashboard
        this.setState({ loading: loading });
        let urlPath = "event/Get-event-users/" + this.state.eventId;

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                country:Country,
                city:city,
                state: state,
                distict: district,
                startDate:startDate,
                endDate:endDate
            })
        }).then((response) => response.json())
            .then((responseData) => {

                // console.log(responseData);

                if (responseData.status) {
                    console.log(responseData.data.response)
                    this.setState({
                        userList: responseData.data.response,
                        totalUsers: responseData.data.response.length
                    });
                    
                    if(Country==""){
                        this.onGetCountryList()
                    }
                    this.state.userList.forEach(d => this.state.copy.push({ 'Sr.no.': this.state.userList.indexOf(d) + 1, FirstName: d.firstName, LastName: d.lastName, nickname: d.nickname, MobileNo: d.countryCode + d.mobileNo, profession: d.profession, otherProfession: d.otherProfession, Address: d.street_address, country: d.country, state: d.state, district: d.district, city: d.city, village: d.village, area: d.area, dob: d.dob, age: d.age, remarks: d.remarks }))

                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )

                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {
                this.setState({ loading: false });
            });

    }



    redirect() {
        if (this.state.isRedirect) {
            return <Navigate to={'/'} />
        }
    }

    handleSelect = (ranges) => {
        console.log(ranges);
        this.setState({ selectionRange: ranges.selection })
        this.state.selectionRange.startDate=ranges.selection.startDate;
        this.state.selectionRange.endDate=ranges.selection.endDate;
        this.getusers(false,ranges.selection.startDate,ranges.selection.endDate,this.state.selectedCountry,this.state.selectedState,this.state.selectedDistrict,this.state.selectedCity)
        
        // {
        //   selection: {
        //     startDate: [native Date Object],
        //     endDate: [native Date Object],
        //   }
        // }
    }

    render() {

        const columns = [
            {
                name: 'Sr No',
                width: "8%",
                sortable: true,
                selector: (row, index) => index + 1
            },
            {
                name: 'Name',
                width: "20%",
                sortable: true,
                selector: row => row.firstName + " " + row.lastName
            },
            {
                name: 'Mobile no.',
                width: "15%",
                sortable: true,
                selector: row => row.countryCode + row.mobileNo
            },
            {
                name: 'DOB',
                width: "15%",
                sortable: true,
                selector: row => row.dob != '' ? moment(row.dob).format("DD-MM-YYYY") : ''
            },
            {
                name: 'Age',
                width: "8%",
                sortable: true,
                selector: row => row.age
            },
            {
                name: 'Address',
                width: "25%",
                sortable: true,
                selector: row => (row.street_address ==undefined || (row.city != null && row.city!=undefined && row.city != '' ? row.city + ", " + row.state + ", " + row.country : '')) || ''
            },
            {
                name:'Action',
                width: "8%",
                sortable: false,
                selector: row=> <Link to={`/UsersDetails/EventUsers_${this.state.eventId}/${row._id}`} className='btn btn-info btn-sm m-1 ' > <span className='menu-text' style={{ color: 'white' }}> <span className=' menu-icon fas fa fa-eye' style={{ color: 'white' }}>  </span></span> </Link>
            }
        ];

        const tableData = {
            columns,
            data: this.state.userList,
        };

        const { dropdownData, showDatePicker, selectionRange } = this.state;

        // console.log(this.state.loading)
        return (
            this.state.loading
                ?
                <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
                :
                <>
                    <div className="d-flex flex-column-fluid">
                        {this.redirect()}
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h3 className="card-title" style={{ cursor: 'pointer' }} onClick={(event) => { (this.state.ShowEventDetails == true ? this.setState({ ShowEventDetails: false }) : this.setState({ ShowEventDetails: true })) }} >Event Details</h3>
                                    {this.state.ShowEventDetails == true ?

                                        <div className='row  bg-white m-6'>
                                            <div className='col-12 row m-2'>
                                                <div className='col-3'>
                                                    <label>Registration status 
                                                        {this.state.RegistrationStatus == "Close" ? <span style={{ color: 'white' }} className='bg-danger ml-2 p-1 rounded pr-2 pl-2'>Close</span> : ""}
                                                        {this.state.RegistrationStatus == "Open" ? <span style={{ color: 'white' }} className='bg-success ml-2 p-1 rounded pr-2 pl-2'>Open</span> : ""}
                                                        {this.state.RegistrationStatus == "UpComing" ? <span className='bg-warning ml-2 p-1 rounded pr-2 pl-2'>UpComing</span> : ""}
                                                    </label>
                                                </div>
                                                <div className='col-5'>
                                                    Registration Date: <label className='h6'>  {moment(this.state.Eventdetails.registrationStartDate).format("DD MMM YYYY")}  to  {moment(this.state.Eventdetails.registrationEndDate).format("DD MMM YYYY")}</label>
                                                </div>
                                                <div className='col-4'>Time: {this.state.Eventdetails.eventStartTime} - {this.state.Eventdetails.eventEndTime} User Count: {this.state.totalUsers}</div>
                                            </div>
                                            <div className='col-12 row m-2'>
                                                <div className='col-3'>
                                                    <label className=' '>Event status 
                                                        {this.state.EventStatus == "Expired" ? <span style={{ color: 'white' }} className='pr-2 pl-2 ml-2 bg-danger p-1 rounded'>Expired</span> : ""}
                                                        {this.state.EventStatus == "Running" ? <span style={{ color: 'white' }} className='bg-success ml-2 p-1 rounded pr-2 pl-2'>Running</span> : ""}
                                                        {this.state.EventStatus == "UpComing" ? <span className='bg-warning ml-2 p-1 rounded pr-2 pl-2'>UpComing</span> : ""}
                                                    </label>
                                                </div>
                                                <div className='col-5'>
                                                    Event Date: <label className='h6'> {moment(this.state.Eventdetails.eventStartDate).format("DD MMM YYYY")} to {moment(this.state.Eventdetails.eventEndDate).format("DD MMM YYYY")} </label>

                                                </div>
                                                <div className='col-4'>Location: <label className='h6'>{this.state.Eventdetails.district}, {this.state.Eventdetails.state}, {this.state.Eventdetails.country}</label></div>
                                            </div>




                                        </div>
                                        : <div></div>}
                                    <h3 className="card-title" style={{ cursor: 'pointer' }} onClick={(event) => { (this.state.ShowFilters == true ? this.setState({ ShowFilters: false }) : this.setState({ ShowFilters: true })) }} >Filters</h3>
                                    {this.state.ShowFilters == true ?

                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className="form-group">
                                                    <label>Date</label><br />

                                                    <DateRangePicker
                                                        ranges={[selectionRange]}
                                                        onChange={this.handleSelect}
                                            className="custom-date-picker"
                                        />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Country / દેશ:</label>
                                                    <Autocomplete
                                                        disablePortal
                                                        disabled={this.state.countries != undefined && this.state.countries != '' && this.state.countries != null && this.state.countries.length > 0 ? false : true}
                                                        id="combo-box-demo1"
                                                        value={this.state.selectedCountry}
                                                        options={this.state.countries}
                                                        onChange={(e, v, r, d) => {
                                                            // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                            if (r == 'selectOption') {
                                                                this.setState({ selectedCountry: v.label })
                                                                this.onGetStateList(v.Code)
                                                                this.getusers(false, this.state.selectionRange.startDate,this.state.selectionRange.endDate, v.label, "", "", "")
                                                                //this.onSelectCountry(v.label)
                                                            } else if (r == 'clear') {
                                                                this.setState({ selectedState: "", stateList: "" })
                                                                this.setState({ selectedDistrict: "", districtList: "" })
                                                                this.setState({ selectedCity: "", cityList: "" })
                                                                this.setState({ selectedCountry: "" })
                                                                this.getusers(false, this.state.selectionRange.startDate,this.state.selectionRange.endDate, "", "", "", "")
                                                            }
                                                        }}

                                                        renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                    />



                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>State / રાજ્ય:</label>
                                                    <Autocomplete
                                                        disablePortal
                                                        disabled={this.state.stateList != undefined && this.state.stateList != '' && this.state.stateList != null && this.state.stateList.length > 0 ? false : true}
                                                        id="combo-box-demo2"
                                                        value={this.state.selectedState}
                                                        options={this.state.stateList}
                                                        onChange={(e, v, r, d) => {
                                                            // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                            if (r == 'selectOption') {
                                                                this.setState({ selectedState: v.label })
                                                                this.onGetDistrictList(v.Code)
                                                                this.getusers(false, this.state.selectionRange.startDate,this.state.selectionRange.endDate, this.state.selectedCountry, v.label, "", "")

                                                            } else if (r == 'clear') {
                                                                this.setState({ selectedState: "" })
                                                                this.setState({ selectedDistrict: "", districtList: "" })
                                                                this.setState({ selectedCity: "", cityList: "" })
                                                                this.getusers(false, this.state.selectionRange.startDate,this.state.selectionRange.endDate, this.state.selectedCountry, "", "", "")
                                                            }
                                                        }}

                                                        renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                    />



                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>District / જિલ્લો:</label>
                                                    <Autocomplete
                                                        disablePortal
                                                        disabled={this.state.districtList != undefined && this.state.districtList != '' && this.state.districtList != null && this.state.districtList.length > 0 ? false : true}
                                                        id="combo-box-demo3"
                                                        value={this.state.selectedDistrict}
                                                        options={this.state.districtList}
                                                        onChange={(e, v, r, d) => {
                                                            // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                            if (r == 'selectOption') {
                                                                this.setState({ selectedDistrict: v.label })
                                                                this.onGetCitiesList(v.Code)
                                                                this.getusers(false, this.state.selectionRange.startDate,this.state.selectionRange.endDate, this.state.selectedCountry, this.state.selectedState, v.label, "")
                                                                //this.onSelectCountry(v.label)
                                                            } else if (r == 'clear') {

                                                                this.setState({ selectedDistrict: "" })
                                                                this.setState({ selectedCity: "", cityList: "" })
                                                                this.getusers(false, this.state.selectionRange.startDate,this.state.selectionRange.endDate, this.state.selectedCountry, this.state.selectedState, "", "")
                                                            }
                                                        }}

                                                        renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                    />



                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">

                                                    <label>City / શહેર:</label>
                                                    <Autocomplete
                                                        disablePortal
                                                        disabled={this.state.cityList != undefined && this.state.cityList != '' && this.state.cityList != null && this.state.cityList.length > 0 ? false : true}
                                                        id="combo-box-demo4"
                                                        value={this.state.selectedCity}
                                                        options={this.state.cityList}
                                                        //value={this.state.selectedCity}
                                                        onChange={(e, v, r, d) => {
                                                            // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                            if (r == 'selectOption') {
                                                                this.setState({ selectedCity: v.label })
                                                                this.getusers(false, this.state.selectionRange.startDate,this.state.selectionRange.endDate, this.state.selectedCountry, this.state.selectedState, this.state.selectedDistrict, v.label)
                                                                //this.onSelectCountry(v.label)
                                                            } else if (r == 'clear') {
                                                                this.setState({ selectedCity: "" })
                                                                this.getusers(false, this.state.selectionRange.startDate,this.state.selectionRange.endDate, this.state.selectedCountry, this.state.selectedState, this.state.selectedDistrict, "")
                                                            }
                                                        }}

                                                        renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                    />


                                                </div>
                                            </div>
                                        </div>
                                : <div></div>}
                                <div className="card card-custom gutter-b example example-compact">
                                    <div className="card-header" >
                                        <div className='col-12 row'>
                                            <div className="col-8 mt-6">

                                                {/* <h3 className="card-title" >Event Users</h3> */}
                                                <h3>{this.state.Eventdetails.eventName}</h3>

                                            </div>
                                            <div className="col-4 ">
                                                <button className=" btn btn-success btn-sm h5 m-3 float-right "
                                                    onClick={(event) => this.exportToExcel(this.state.copy)} >Export to Excel</button>
                                                <Link to={'/EventList'} className="btn btn-danger btn-sm h5 m-3  mr-2 float-right" >
                                                    <span className="menu-text" style={{ color: 'white' }}>Back to List</span>
                                                </Link>

                                            </div>
                                        </div>


                                    </div>
                                    <div className="card-body row col-12" >

                                        <div className='col-md-12'>
                                            {this.state.userList.length > 0 ?

                                                // <table className="table table-head-custom" id="kt_datatable1">
                                                //     <thead>
                                                //         <tr>
                                                //             <th>Sr No</th>
                                                //             <th>Name</th>
                                                //             <th>Mobile no.</th>
                                                //             <th>Email</th>
                                                //             <th>Gender</th>
                                                //             <th>Address</th>
                                                //         </tr>
                                                //     </thead>
                                                //     <tbody>
                                                //         {

                                                //             this.state.userList.map((item, i) => {
                                                //                 return (
                                                //                     <tr>
                                                //                         <td style={{ width: '10%', textAlign: 'center' }}>{i + 1}</td>
                                                //                         <td style={{ width: '30%' }}>

                                                //                             <span className="form-text">{item.eventId}</span>

                                                //                         </td>
                                                //                         <td style={{ width: '20%' }}>
                                                //                         </td>
                                                //                         <td style={{ width: '20%' }}>
                                                //                         </td>

                                                //                         <td style={{ width: '20%' }}>
                                                //                         </td>
                                                //                         <td style={{ width: '20%' }}>
                                                //                         </td>
                                                //                     </tr>)
                                                //             })
                                                //         }
                                                //     </tbody>
                                                // </table>
                                                <DataTableExtensions
                                                    {...tableData}
                                                    export={false}
                                                    print={false}
                                                >
                                                    <DataTable
                                                        noHeader
                                                        defaultSortField="id"
                                                        defaultSortAsc={false}
                                                        pagination
                                                        highlightOnHover
                                                        paginationPerPage={30}
                                                    />
                                                </DataTableExtensions>
                                                :
                                                <div className="col-md-12" style={{ marginTop: 20 }}>
                                                    <div className="col-md-12" style={{ justifyContent: 'center', alignItems: 'center', backgroundImage: " linear-gradient(to right,#D73C34, #B32A61,#90178E)", minHeight: "50px", borderRadius: 5, padding: '15px 20px', display: 'flex' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', }}>
                                                            <span className="" style={{ fontSize: "15px", color: '#ededed', marginBottom: 0 }}>No Users</span>

                                                        </div>


                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>

                                    <div className="card-footer" style={{ justifyContent: 'center', alignItems: 'center' }}>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                </>
        );
    }
}

export default withRouter(EventUsers);