import React, { Component, useRef } from 'react';
import { Dots, Spinner } from 'react-activity';
import "react-activity/dist/Dots.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// utilities
import Functions from '../Utilities/Functions';
import Constants from '../Utilities/Constants';
import moment from 'moment';

import AuthController from '../Utilities/Auth';
import withRouter from '../Utilities/withRouter'
import { Link, Navigate } from 'react-router-dom';
import Axios from 'axios';
import "react-activity/dist/Dots.css";

import { appendScript } from '../Utilities/appendScript';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


class Event extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        const { id } = props.params;
        this.state = {
            loading: false,
            errors: {},
            ResErrors: {},
            isRedirect: false,
            sangathanId: null,
            assignRole: "",
            eventName: "",
            image: null,
            EventType: "",
            eventDescription: "",
            eventBannerImage: "",
            eventStartDate: "",
            eventEndDate: "",
            registrationStartDate: "",
            registrationEndDate: "",
            eventStartTime: "",
            eventEndTime: "",
            location: "",
            Locations: [],

            countries: [],
            stateList: [],
            districtList: [],
            CityList: [],

            selectedCity: "",
            selectedCountry: "",
            selectedDistrict: "",
            selectedState: "",

            country: "",
            state: "",
            district: "",
            city: "",
            countryDataCode: "",
            stateCode: "",
            districtCode: "",
            cityCode: "",
            Address:"",
            // MemberCount: 0,
            // Childs: 0,
            // Adults: 0,
            IsCanceled: false,
            _id: id|| ''
        };

    }

    async componentDidMount() {


        // await appendScript('/assets/js/pages/crud/file-upload/image-input.js');
        if (this.state._id !== '' && this.state._id != null && this.state._id != undefined) {

            let heading = {
                title: 'Event',
                subTitle: ['Edit Event']
            };
            localStorage.setItem('heading', JSON.stringify(heading));

            await this.getEvent();
        } else {
            let heading = {
                title: 'Event',
                subTitle: ['Event User']
            };
            localStorage.setItem('heading', JSON.stringify(heading));
            await this.onGetLocationsList();
            await this.onGetCountryList();
        }

       
    }

    getEvent = async () => {
        let urlPath = "event/eventdetails/" + this.state._id

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((responseData) => {

                if (responseData.status) {
                    var datares = responseData.data
                   
                    this.setState({
                        eventName: datares.eventName,
                        name: datares.eventName,
                        eventDescription: datares.eventDescription,
                        eventBannerImage: datares.eventBannerImage,
                        eventStartDate: new Date(datares.eventStartDate),
                         eventEndDate: new Date(datares.eventEndDate),
                         registrationStartDate: new Date(datares.registrationStartDate),
                         registrationEndDate: new Date(datares.registrationEndDate),
                        eventStartTime: datares.eventStartTime,
                        eventEndTime: datares.eventEndTime,
                        location: datares.location,
                        EventType: datares.EventType,
                        country: datares.country,
                        state: datares.state,
                        district: datares.district,
                        city: datares.city,
                        countryDataCode: datares.countryDataCode,
                        stateCode: datares.stateCode,
                        districtCode: datares.districtCode,
                        cityCode: datares.cityCode,
                        isCanceled:datares.isCanceled,
                        Address:datares.Address
                    });
                     this.onGetLocationsList();
                     this.onGetCountryList();
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    // this.setState({ loading: false });
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {
                // return this.setState({ isInvitingUser: false })
            });

    }

    onGetLocationsList = async () => {
        await this.setState({ loading: true })

        let urlPath = "DynamicPage/entry-locations/"

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status && 'data' in responseData) {
                    const { data } = responseData;
                    const LocationsList = data.filter(item => item.status === "true").map((item) => {
                        return { ...item, Name: item.name, id: item.place_id, Value: item.name }
                    })

                    if (LocationsList.length > 0) {
                        this.state.Locations = LocationsList;
                        
                        if(this.state.location!=''){
                            var LocationsL=LocationsList.filter(s=>s.place_id==this.state.location)[0]
                            this.getFormattedAddressComponentData(LocationsL?.addressComponent || null)
                        }else{
                            
                            this.setState({ location: LocationsList[0].place_id })
                            this.getFormattedAddressComponentData(LocationsList[0]?.addressComponent || null)
                        }
                        
                        // setSelectedLocation(LocationsList[0])


                    }

                    setTimeout(() => {
                        this.setState({ Locations: LocationsList })
                    }, 100)
                    //   await this.setState({ RoleList, loading: true })

                } else if (!responseData.status && "auth" in responseData) {
                    // AuthController.Logout();
                } else if (!responseData.status && responseData.error) {
                    // // console.log("Something went wrong")
                    this.setState({ loading: false })
                } else {
                    // // console.log("Something went wrong")
                    this.setState({ loading: false })
                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }
    onGetCountryList = async () => {
        await this.setState({ loading: true })

        let urlPath = "auth/CountryList"

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;

                    let countries = []

                    data.map((c, i) => {
                        countries.push({ label: c.country_name, id: c._id, Code: c.id })
                    })

                    console.log(data)
                    this.setState({ countries: countries, refreshInput: false })

                    if (this.state.selectedCountry != '' && this.state.selectedCountry != null) {
                        this.state.stateCode = countries.filter(s => s.label == this.state.selectedCountry)[0].Code
                        console.log(this.state.stateCode)
                        this.onGetStateList(this.state.stateCode);
                    } else {
                        this.state.selectedCity = ""

                        this.state.selectedDistrict = ""
                        this.state.selectedState = ""
                    }
                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }
    onGetStateList = async (data) => {
        console.log(data)
        this.state.countryDataCode = data
        if (data != null && data != undefined && data != '') {

            let urlPath = "auth/StatesList/" + data

            fetch(Constants.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body: Body,
            }).then((response) => response.json())
                .then(async (responseData) => {
                    // // // console.log("RES ",responseData)
                    if (responseData.status) {
                        var data = responseData.response;

                        let stateList = []

                        data.map((c, i) => {
                            stateList.push({ label: c.state_title, id: c._id, Code: c.state_id })
                        })
                        console.log(stateList)
                        this.setState({ stateList: stateList, refreshInput: false })

                        if (this.state.selectedState != '' && this.state.selectedState != null) {
                            this.state.stateCode = stateList.filter(s => s.label == this.state.selectedState)[0].Code
                            console.log(this.state.stateCode)
                            this.onGetDistrictList(this.state.stateCode);
                        } else {
                            this.state.selectedState = ""
                            this.state.selectedDistrict = ""
                            this.state.selectedCity = ""

                        }

                    }
                }).catch(error => {
                    // // console.log("err", error);

                }).finally(final => {
                    //return this.setState({ loading: false })
                });
        }
    }
    onGetDistrictList = async (data) => {
        this.state.stateCode = data

        let urlPath = "auth/DistrictsList/" + data

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let districtList = []

                    data.map((c, i) => {
                        districtList.push({ label: c.district_title, id: c._id, Code: c.districtid })
                    })

                    this.setState({ districtList: districtList, refreshInput: false })

                    if (this.state.selectedDistrict != '' && this.state.selectedDistrict != null) {
                        this.state.districtCode = districtList.filter(s => s.label == this.state.selectedDistrict)[0].Code
                        console.log(this.state.districtCode)
                        this.onGetCitiesList(this.state.districtCode);
                    } else {
                        this.state.selectedDistrict = ""
                        this.state.selectedCity = ""
                    }


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }
    onGetCitiesList = async (data) => {
        this.state.districtCode = data
        let urlPath = "auth/CitiesList/" + data

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let CityList = []
                    
                    data.map((c, i) => {
                        CityList.push({ label: c.name, id: c._id, Code: c.id })
                    })
                    if (this.state.selectedCity != '' && this.state.selectedCity != null) {
                        this.state.cityCode = CityList.filter(s => s.label == this.state.selectedCity)[0].Code
                        
                    } else {
                       
                        this.state.selectedCity = ""
                    }
                    this.setState({ CityList: CityList, refreshInput: false })


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }

    getFormattedAddressComponentData = (AddressDetails) => {
        if (!AddressDetails) {
            // console.log("NULL FOUND")
            // this.setState({ city: "" })
            // this.setState({ district: "" })
            // this.setState({ state: "" })
            // this.setState({ country: "" })
            // this.setState({ street_address: "", defaultAddress : "", addressComponent : null, refreshInput : true });
            // setTimeout(() => {
            //     this.setState({ refreshInput : false })
            // },100)
            this.cleanAddress()
            // this.setAutocompleteValue("")
            return ""
        }
        console.log("\n details =====->", AddressDetails);
        let address_components = AddressDetails.address_components;
        address_components.map((address, index) => {
            if (address.types[0] == 'locality') {
                this.setState({ city: address.long_name, selectedCity: address.long_name })
            }
            if (address.types[0] == 'administrative_area_level_3') {
                this.setState({ district: address.long_name, selectedDistrict: address.long_name })
            }
            if (address.types[0] == 'administrative_area_level_1') {
                this.setState({ state: address.long_name, selectedState: address.long_name })
            }
            if (address.types[0] == 'country') {
                this.setState({ country: address.long_name, selectedCountry: address.long_name })

            }

        })

        // this.setAutocompleteValue(AddressDetails.formatted_address)
        return AddressDetails.formatted_address
    }
    onSelectLocation = (e) => {
        let location = e.target.value

        const foundItem = this.state.Locations.find(item => item.place_id == location);
        // console.log("LOC ",foundItem,this.getFormattedAddressComponentData(foundItem?.addressComponent || null) )
        this.state.optionalFieldAllow = foundItem?.optionalFieldAllow;
        this.setState({ selectedLocation: location,location:location, defaultAddress: this.getFormattedAddressComponentData(foundItem?.addressComponent || null), refreshInput: true })

        setTimeout(() => {
            this.setState({ refreshInput: false })
        }, 100)
        // setResult(foundItem || null);
    }
    /**
     *
     * @param null
     * @description This function validate inputs
     * @returns boolean
     */
    handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (this.state.eventName?.trim() === "") {
            formIsValid = false;
            errors.eventName = "Please enter event Name";
        }
        this.setState({ errors: errors });

        return formIsValid;
    }


    onUserInvite = async (e) => {
        e.preventDefault()

        this.setState({ loading: true })

        if (!this.handleValidation()) {
            return false
        }
        // const { state, signOut } = this.context;
        // await this.setState({ isInvitingUser: true })
        const { navigation } = this.props;
        const { _id, eventName, eventDescription, eventBannerImage, eventStartDate, eventEndDate, registrationStartDate, registrationEndDate, eventStartTime,
            eventEndTime, token, image,IsCanceled,Address,country,state,district,city,countryDataCode,stateCode,districtCode,cityCode,EventType,location } = this.state;

        const formData = new FormData();
        formData.append('_id', _id);
        formData.append('eventName', eventName);
        formData.append('eventDescription', eventDescription);
        formData.append('Address',Address)
        // if(image != null) {
        //     // formData.append('eventBannerImage', image);
        //     formData.append('image', image);
        //   }
        if (eventBannerImage != null) {
            formData.append('eventBannerImage', image);
            // formData.append('eventBannerImage', eventBannerImage);
        }
        formData.append('eventStartDate', eventStartDate != '' ? moment(eventStartDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '');
        formData.append('eventEndDate', eventEndDate != '' ? moment(eventEndDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '');
        formData.append('registrationStartDate', registrationStartDate != '' ? moment(registrationStartDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '');
        formData.append('registrationEndDate', registrationEndDate != '' ? moment(registrationEndDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '');
        formData.append('eventStartTime', eventStartTime);
        formData.append('eventEndTime', eventEndTime);
        formData.append('isCanceled', IsCanceled);

        formData.append('location', location);
        formData.append('EventType', EventType);
        formData.append('country', country);
        formData.append('state', state);
        formData.append('district', district);
        formData.append('city', city);
        formData.append('countryDataCode', countryDataCode);
        formData.append('stateCode', stateCode);
        formData.append('districtCode', districtCode);
        formData.append('cityCode', cityCode);

        let urlPath = "event/create-event";

        // return
        // postFormData(Constants.VUF_APP_API + urlPath, formData, { headers: { credentials: 'include', Accept: "*/*" } }).then((responseData) => {
        console.log(formData)

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'POST',
            headers: {
                'Authorization': token
            },
            body: formData,
            redirect: 'follow',
        }).then((response) => response.json())
            .then((responseData) => {
                console.log(responseData);
                if (responseData.status) {
                    if(this.state._id==null || this.state._id==''  || this.state._id==undefined  ){
                        alert("Event Successfully Added.")
                    }else{
                        alert("Event Successfully Updated.")
                    }
                     
                       this.setState({ loading: false});
                      window.location.href = "/EventList";
                   

                } else if (!responseData.status && "auth" in responseData) {
                    AuthController.Logout();
                } else if (!responseData.status && responseData.error) {
                    alert(
                        responseData?.error || "Error")
                    this.setState({ loading: false });
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    this.setState({ loading: false });
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                this.setState({ loading: false });
            }).finally(final => {
                // return this.setState({ isInvitingUser: false })
            });
    }


    redirect() {
        if (this.state.isRedirect) {
            return <Navigate to={'/'} />
        }
    }

    render() {

        const { dropdownData } = this.state;

        const fileTypes = ["JPG", "PNG"];
        // console.log(this.state.loading)
        return (
            this.state.loading
                ?
                <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
                :
                <>
                    <div className="d-flex flex-column-fluid">
                        {this.redirect()}
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">

                                    <div className="card card-custom gutter-b example example-compact">
                                        <div className="card-header" >
                                            {/* style={{backgroundImage:"url(assets/images/bg/bg-2.jpg)", minHeight:"150px"}} */}
                                            {/* <h3 className="card-title"> {this.state.name ? 'Edit' : 'Add'} {this.state.catName}</h3> */}
                                            <h3 className="card-title" >Add Event</h3>

                                        </div>
                                        <div className="card-body row col-12" >

                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Name</label>
                                                            <input type="text" name="eventName" className="form-control form-control-solid" placeholder="Enter event name" onChange={(event) => Functions.onChange(event, this)} value={this.state.eventName} />
                                                            <span className="form-text text-danger">{this.state.errors?.eventName}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">

                                                            <label>Upload Banner</label><br />
                                                            <input type="file" name="image"
                                                                onChange={(event) => {
                                                                    this.setState({ image: event.target.files[0] })
                                                                }}
                                                                className="form-control form-control-file" data-buttonname="btn-secondary"
                                                            />

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Location:</label><br />

                                                            <select className="form-control form-select-solid" style={{ backgroundColor: '#F3F6F9' }} aria-label="Select Location" onChange={(event) => this.onSelectLocation(event)} >

                                                                {/* <option value="">Select Location</option> */}
                                                                {
                                                                    this.state.Locations.map((location) => {
                                                                        return (<option selected={this.state.location == location.place_id} value={location.place_id}>{location.name}</option>)
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Event Type:</label><br />
                                                            <select value={this.state.EventType} className="form-control form-select-solid" onChange={(e) => { this.setState({ EventType: e.target.value }) }} style={{ backgroundColor: '#F3F6F9' }} aria-label="Select Event Type" >
                                                                <option value="" selected>Select Event Type</option>
                                                                <option value="Private" >Private</option>
                                                                <option value="Public" >Public</option>
                                                                <option value="Meeting" >Meeting</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Country / દેશ:</label>
                                                            <Autocomplete
                                                                disablePortal
                                                                disabled={this.state.countries.length > 0 ? false : true}
                                                                id="combo-box-demo"
                                                                value={this.state.selectedCountry}
                                                                options={this.state.countries}
                                                                onChange={(e, v, r, d) => {
                                                                    // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                    if (r == 'selectOption') {
                                                                        this.setState({ selectedCountry: v.label,country:v.label })
                                                                        this.onGetStateList(v.Code)
                                                                        //this.onSelectCountry(v.label)
                                                                    } else if (r == 'clear') {
                                                                        this.setState({ selectedState: "" })
                                                                        this.setState({ selectedDistrict: "" })
                                                                        this.setState({ selectedCity: "" })
                                                                        this.setState({ selectedCountry: "" })
                                                                    }
                                                                }}

                                                                renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                            />



                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>State / રાજ્ય:</label>
                                                            <Autocomplete
                                                                disablePortal
                                                                disabled={this.state.stateList.length > 0 ? false : true}
                                                                id="combo-box-demo"
                                                                value={this.state.selectedState}
                                                                options={this.state.stateList}
                                                                onChange={(e, v, r, d) => {
                                                                    // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                    if (r == 'selectOption') {
                                                                        this.setState({ selectedState: v.label,state: v.label })
                                                                        this.onGetDistrictList(v.Code)

                                                                    } else if (r == 'clear') {
                                                                        this.setState({ selectedDistrict: "" })
                                                                        this.setState({ selectedCity: "" })
                                                                        this.setState({ selectedState: "" })
                                                                    }
                                                                }}

                                                                renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                            />



                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>District / જિલ્લો:</label>
                                                            <Autocomplete
                                                                disablePortal
                                                                disabled={this.state.districtList.length > 0 ? false : true}
                                                                id="combo-box-demo"
                                                                value={this.state.selectedDistrict}
                                                                options={this.state.districtList}
                                                                onChange={(e, v, r, d) => {
                                                                    // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                    if (r == 'selectOption') {
                                                                        this.setState({ selectedDistrict: v.label,district : v.label })
                                                                        this.onGetCitiesList(v.Code)
                                                                        //this.onSelectCountry(v.label)
                                                                    } else if (r == 'clear') {
                                                                        this.setState({ selectedCity: "" })
                                                                        this.setState({ selectedDistrict: "" })
                                                                    }
                                                                }}

                                                                renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                            />



                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>City / શહેર:</label>
                                                            <Autocomplete
                                                                disablePortal
                                                                disabled={this.state.CityList.length > 0 ? false : true}
                                                                id="combo-box-demo"
                                                                options={this.state.CityList}
                                                                value={this.state.selectedCity}
                                                                onChange={(e, v, r, d) => {
                                                                    // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                    if (r == 'selectOption') {
                                                                        this.setState({ selectedCity: v.label, cityCode: v.Code,city: v.label, })
                                                                        // this.onGetVillageList(v.Code)
                                                                        //this.onSelectCountry(v.label)
                                                                    } else if (r == 'clear') {

                                                                        this.setState({ selectedCity: "" })
                                                                    }
                                                                }}

                                                                renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                            />
                                                            <span className="form-text text-danger">{this.state.errors?.selectedCity}</span>



                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label>Start Date</label><br />
                                                            {/* <input type="text" name="eventStartDate" className="form-control form-control-solid" placeholder="Enter Description" onChange={(event) => Functions.onChange(event, this)} value={this.state.eventStartDate} /> */}

                                                            <DatePicker className="form-control form-control-solid" name="eventStartDate"
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={this.state.eventStartDate}
                                                                onChange={(e) => { this.setState({ eventStartDate: e }) }}
                                                                // onChange={(event) => Functions.onChange(event, this)} 
                                                                value={this.state.eventStartDate}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label>Event Start Time</label>

                                                            <input type="time" name="eventStartTime" className="form-control form-control-solid" onChange={(event) => Functions.onChange(event, this)} value={this.state.eventStartTime} />
                                                        </div>
                                                    </div>

                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label>End Date</label><br />
                                                            <DatePicker className="form-control form-control-solid" name="eventEndDate"
                                                                // filterDate={(d) => { return this.state.eventStartDate <= d }}
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={this.state.eventEndDate}
                                                                onChange={(e) => { this.setState({ eventEndDate: e }) }}
                                                                value={this.state.eventEndDate}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label>Event End Time</label>
                                                            <input type="time" name="eventEndTime" className="form-control form-control-solid" placeholder="Enter Description" onChange={(event) => Functions.onChange(event, this)} value={this.state.eventEndTime} />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label>Registration Start Date</label><br />
                                                            <DatePicker className="form-control form-control-solid" name="registrationStartDate"
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={this.state.registrationStartDate}
                                                                onChange={(e) => { this.setState({ registrationStartDate: e }) }}
                                                                value={this.state.registrationStartDate}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className="form-group">
                                                            <label>Registration End Date</label><br />
                                                            <DatePicker className="form-control form-control-solid" name="registrationEndDate"
                                                                // filterDate={(d) => { return this.state.registrationStartDate <= d }}
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={this.state.registrationEndDate}
                                                                onChange={(e) => { this.setState({ registrationEndDate: e }) }} value={this.state.registrationEndDate}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label>Venue</label>
                                                        <textarea type="text" name="Address" className="form-control form-control-solid" placeholder="Enter venue" onChange={(event) => Functions.onChange(event, this)} value={this.state.Address} ></textarea>
                                                        <span className="form-text text-danger">{this.state.errors?.Address}</span>
                                                    </div>
                                                    {/* <div className='col-md-6 '>
                                                        <label>Famliy Member</label><br />
                                                        <div className='row'>
                                                            <label className='mt-3 ml-4'>Adults:</label>  <div className='col-3'> <input type="number" min="0" max="10" name="eventAdult" className="form-control " onChange={(event) => { this.setState({ Adults: event.target.value }); Functions.onChange(event, this) }} value={this.state.Adults} /></div>
                                                            <label className='mt-3'>Childs:</label>    <div className='col-3'>  <input type="number" min="0" max="10" name="eventChild" className="form-control " onChange={(event) => { this.setState({ Childs: event.target.value }); Functions.onChange(event, this) }} value={this.state.Childs} /></div>
                                                            <span className="form-text text-danger">{this.state.errors?.eventDescription}</span>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className='row'>
                                                <div className='col-md-6'>
                                                        <label>Description</label>
                                                        <textarea type="text" name="eventDescription" className="form-control form-control-solid" placeholder="Enter Description" onChange={(event) => Functions.onChange(event, this)} value={this.state.eventDescription} ></textarea>
                                                        <span className="form-text text-danger">{this.state.errors?.eventDescription}</span>
                                                    </div>
                                               
                                                    <div className='col-md-6'>
                                                        <label className="checkbox ">
                                                            <input type="checkbox" name="OptionalField" onChange={(event) => { (this.state.IsCanceled == true ? this.setState({ IsCanceled: false }) : this.setState({ IsCanceled: true })) }}
                                                                value={this.state.IsCanceled}
                                                                defaultChecked={this.state.IsCanceled}
                                                            />
                                                            <span className='mr-3 ' ></span> IsCanceled </label>
                                                    </div>
                                                </div>




                                            </div>
                                        </div>

                                        <div className="card-footer" style={{ justifyContent: 'center', alignItems: 'center' }}>

                                            <button type="button" className="btn btn-primary mb-2" onClick={(event) => this.onUserInvite(event)}>
                                                {this.state._id == '' || this.state._id == null || this.state._id == undefined ?
                                                    <label>Add Event</label> :
                                                    <label>Edit Event</label>
                                                }
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        );
    }
}

export default withRouter(Event);
