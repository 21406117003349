import React, { Component, useRef } from 'react';
import { Dots, Spinner } from 'react-activity';
import "react-activity/dist/Dots.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// utilities
import Functions from '../Utilities/Functions';
import Constants from '../Utilities/Constants';
import moment from 'moment';

import AuthController from '../Utilities/Auth';
import withRouter from '../Utilities/withRouter'
import { Link, Navigate, Redirect } from 'react-router-dom';
import Axios from 'axios';
import "react-activity/dist/Dots.css";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


class LocationAdd extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        const { id } = props.params;
        this.state = {
            loading: false,
            errors: {},
            ResErrors: {},
            isRedirect: false,
            sangathanId: null,
            assignRole: "",
            status: "true",
            name: "",
            place_id: "",
            optionalFieldAllow: null,
            selectedLocation: "",
            addressComponent: null,
            Locations: [],
            _id: id,
            defaultAddress: "",

            countries: [],
            stateList: [],
            districtList: [],
            CityList: [],

            selectedCity: "",
            selectedCountry: "",
            selectedDistrict: "",
            selectedState: "",

            country: "",
            state: "",
            district: "",
            city: "",
            countryDataCode: "",
            stateCode: "",
            districtCode: "",
            cityCode: "",
        };

    }

    async componentDidMount() {

        // await appendScript('/assets/js/pages/crud/file-upload/image-input.js');


        if (this.state._id !== '' && this.state._id != null && this.state._id != undefined) {

            let heading = {
                title: 'Location',
                subTitle: ['Edit Location']
            };
            localStorage.setItem('heading', JSON.stringify(heading));

            await this.getLocation();
        } else {

            let heading = {
                title: 'Location',
                subTitle: ['Add Location']
            };
            localStorage.setItem('heading', JSON.stringify(heading));

            this.setState({ loading: false });
            await this.onGetCountryList();
        }

    }



    /**
     *
     * @param null
     * @description This function validate inputs
     * @returns boolean
     */
    handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (this.state.name?.trim() === "") {
            formIsValid = false;
            errors.name = "Please enter event Name";
        }
        this.setState({ errors: errors });

        return formIsValid;
    }
    getLocation = () => {

        let urlPath = "location/GetLocationDetails/" + this.state._id

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((responseData) => {



                if (responseData.status) {
                    var datares = responseData.data
                    console.log(datares.name);
                    this.setState({
                        eventName: datares.name,
                        name: datares.name,
                        place_id: datares.place_id,
                        status: datares.status,
                        optionalFieldAllow: datares.optionalFieldAllow,
                        country: datares.country,
                        state: datares.state,
                        district: datares.district,
                        city: datares.city,
                        selectedCountry: datares.country,
                        selectedState: datares.state,
                        selectedDistrict: datares.district,
                        selectedCity: datares.city,
                        countryDataCode: datares.countryDataCode,
                        stateCode: datares.stateCode,
                        districtCode: datares.districtCode,
                        cityCode: datares.cityCode,
                        addressComponent: datares.addressComponent, defaultAddress: datares.addressComponent.formatted_address
                    });
                    this.onGetCountryList();
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    // this.setState({ loading: false });
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {
                // return this.setState({ isInvitingUser: false })
            });

    }
    onGetCountryList = async () => {
        await this.setState({ loading: true })

        let urlPath = "auth/CountryList"

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;

                    let countries = []

                    data.map((c, i) => {
                        countries.push({ label: c.country_name, id: c._id, Code: c.id })
                    })

                    console.log(data)
                    this.setState({ countries: countries, refreshInput: false })

                    if (this.state.selectedCountry != '' && this.state.selectedCountry != null) {
                        this.state.stateCode = countries.filter(s => s.label == this.state.selectedCountry)[0].Code
                        console.log(this.state.stateCode)
                        this.onGetStateList(this.state.stateCode);
                    } else {
                        this.state.selectedCity = ""

                        this.state.selectedDistrict = ""
                        this.state.selectedState = ""
                    }
                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }
    onGetStateList = async (data) => {
        console.log(data)
        this.state.countryDataCode = data
        if (data != null && data != undefined && data != '') {

            let urlPath = "auth/StatesList/" + data

            fetch(Constants.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body: Body,
            }).then((response) => response.json())
                .then(async (responseData) => {
                    // // // console.log("RES ",responseData)
                    if (responseData.status) {
                        var data = responseData.response;

                        let stateList = []

                        data.map((c, i) => {
                            stateList.push({ label: c.state_title, id: c._id, Code: c.state_id })
                        })
                        console.log(stateList)
                        this.setState({ stateList: stateList, refreshInput: false })

                        if (this.state.selectedState != '' && this.state.selectedState != null) {
                            this.state.stateCode = stateList.filter(s => s.label == this.state.selectedState)[0].Code
                            console.log(this.state.stateCode)
                            this.onGetDistrictList(this.state.stateCode);
                        } else {
                            this.state.selectedState = ""
                            this.state.selectedDistrict = ""
                            this.state.selectedCity = ""

                        }

                    }
                }).catch(error => {
                    // // console.log("err", error);

                }).finally(final => {
                    //return this.setState({ loading: false })
                });
        }
    }
    onGetDistrictList = async (data) => {
        this.state.stateCode = data

        let urlPath = "auth/DistrictsList/" + data

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let districtList = []

                    data.map((c, i) => {
                        districtList.push({ label: c.district_title, id: c._id, Code: c.districtid })
                    })

                    this.setState({ districtList: districtList, refreshInput: false })

                    if (this.state.selectedDistrict != '' && this.state.selectedDistrict != null) {
                        this.state.districtCode = districtList.filter(s => s.label == this.state.selectedDistrict)[0].Code
                        console.log(this.state.districtCode)
                        this.onGetCitiesList(this.state.districtCode);
                    } else {
                        this.state.selectedDistrict = ""
                        this.state.selectedCity = ""
                    }


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }
    onGetCitiesList = async (data) => {
        this.state.districtCode = data
        let urlPath = "auth/CitiesList/" + data

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let CityList = []

                    data.map((c, i) => {
                        CityList.push({ label: c.name, id: c._id, Code: c.id })
                    })
                    if (this.state.selectedCity != '' && this.state.selectedCity != null) {
                        this.state.cityCode = CityList.filter(s => s.label == this.state.selectedCity)[0].Code

                    } else {

                        this.state.selectedCity = ""
                    }
                    this.setState({ CityList: CityList, refreshInput: false })


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }

    getFormattedAddressComponentData = (AddressDetails) => {
        if (!AddressDetails) {
            // console.log("NULL FOUND")
            // this.setState({ city: "" })
            // this.setState({ district: "" })
            // this.setState({ state: "" })
            // this.setState({ country: "" })
            // this.setState({ street_address: "", defaultAddress : "", addressComponent : null, refreshInput : true });
            // setTimeout(() => {
            //     this.setState({ refreshInput : false })
            // },100)
            this.cleanAddress()
            // this.setAutocompleteValue("")
            return ""
        }
        console.log("\n details =====->", AddressDetails);
        let address_components = AddressDetails.address_components;
        address_components.map((address, index) => {
            if (address.types[0] == 'locality') {
                this.setState({ city: address.long_name, selectedCity: address.long_name })
            }
            if (address.types[0] == 'administrative_area_level_3') {
                this.setState({ district: address.long_name, selectedDistrict: address.long_name })
            }
            if (address.types[0] == 'administrative_area_level_1') {
                this.setState({ state: address.long_name, selectedState: address.long_name })
            }
            if (address.types[0] == 'country') {
                this.setState({ country: address.long_name, selectedCountry: address.long_name })

            }

        })

        // this.setAutocompleteValue(AddressDetails.formatted_address)
        return AddressDetails.formatted_address
    }

    onUserInvite = async (e) => {
        e.preventDefault()

        this.setState({ loading: true })
        const token = await localStorage.getItem('token');
        if (!this.handleValidation()) {
            return false
        }
        // const { state, signOut } = this.context;
        // await this.setState({ isInvitingUser: true })
        const { navigation } = this.props;
        const { name, place_id, status, optionalFieldAllow, addressComponent, _id, selectedCountry, selectedState, selectedDistrict, selectedCity, countryDataCode, stateCode, districtCode, cityCode } = this.state;

        const Body = JSON.stringify({
            name: name,
            place_id: place_id,
            status: status,
            optionalFieldAllow: optionalFieldAllow,
            addressComponent: addressComponent,
            country: selectedCountry,
            state: selectedState,
            district: selectedDistrict,
            city: selectedCity,
            countryDataCode: countryDataCode,
            stateCode: stateCode,
            districtCode: districtCode,
            cityCode: cityCode,
            _id: _id
        })



        let urlPath = "location/create-location";
        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                if (responseData.status && 'response' in responseData) {
                    if (this.state._id == null || this.state._id == '' || this.state._id == undefined) {
                        alert("Location Successfully Added.")
                    } else {
                        alert("Location Successfully Updated.")
                    }

                    await this.setState({ loading: false });
                    window.location.href = "/LocationList";
                    // this.redirectWithPath("/LocationList")
                } else if (!responseData.status && "auth" in responseData) {
                    AuthController.Logout();
                } else if (!responseData.status && responseData.error) {
                    alert(
                        responseData?.error || "Error")
                    this.setState({ loading: false });
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    this.setState({ loading: false });
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                this.setState({ loading: false });
            }).finally(final => {
                return this.setState({ loading: false })
            });
    }
    redirectWithPath(path) {
        // sangathan-create
        return <Navigate to={'/LocationList'} />
    }
    getAddressComponentData = (AddressDetails, isPlaceSelected, e = null) => {
        // e.preventDefault()
        if (!AddressDetails) {
            // console.log("IS PlACE ",AddressDetails,isPlaceSelected,e)
            return
        }

        let addressComponentObj = { address_components: AddressDetails.address_components };

        // console.log(JSON.stringify(addressComponentObj));

        this.setState({ addressComponent: addressComponentObj });
    }

    redirect() {
        if (this.state.isRedirect) {
            return <Navigate to={'/'} />
        }
    }

    render() {

        const { dropdownData } = this.state;

        const fileTypes = ["JPG", "PNG"];
        // console.log(this.state.loading)
        return (
            this.state.loading
                ?
                <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
                :
                <>
                    <div className="d-flex flex-column-fluid">
                        {this.redirect()}
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">

                                    <div className="card card-custom gutter-b example example-compact">
                                        <div className="card-header" >
                                            {this.state._id == '' || this.state._id == null || this.state._id == undefined ?
                                                <h3 className="card-title" >Add Location</h3> :
                                                <h3 className="card-title" >Edit Location</h3>
                                            }
                                        </div>
                                        <div className="card-body row col-12" >

                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Name</label>
                                                            <input type="text" name="name" className="form-control form-control-solid"
                                                                placeholder="Enter name" onChange={(event) => Functions.onChange(event, this)} value={this.state.name} />
                                                            <span className="form-text text-danger">{this.state.errors?.name}</span>
                                                        </div>
                                                    </div>
                                                    {/* <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label>Place ID</label>
                                                            <input type="text" name="place_id" className="form-control form-control-solid"
                                                                placeholder="Enter place id" onChange={(event) => Functions.onChange(event, this)} value={this.state.place_id} />
                                                            <span className="form-text text-danger">{this.state.errors?.place_id}</span>
                                                        </div>
                                                    </div> */}

                                                    <div className='col-md-6'>
                                                        <div className="form-group mt-10">
                                                            <div className="checkbox-inline ">
                                                                {/* <label className="checkbox mr-3">
                                                           
                                                                    <input type="checkbox" name="Status"  value={this.state.status}  onChange={(event) => { (this.state.status=="true"? this.setState({ status : "false" }) :this.setState({ status : "true" })) }}
                                                                    defaultChecked={this.state.status} />
                                                                    <span  ></span>Status</label> */}

                                                                <label className="checkbox">
                                                                    <input type="checkbox" name="OptionalField" onChange={(event) => { (this.state.optionalFieldAllow == true ? this.setState({ optionalFieldAllow: false }) : this.setState({ optionalFieldAllow: true })) }}
                                                                        value={this.state.optionalFieldAllow}
                                                                        defaultChecked={this.state.optionalFieldAllow}
                                                                    />
                                                                    <span  ></span>Optional Field Allow</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className='row'>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Country / દેશ:</label>
                                                            <Autocomplete
                                                                disablePortal
                                                                disabled={this.state.countries.length > 0 ? false : true}
                                                                id="combo-box-demo"
                                                                value={this.state.selectedCountry}
                                                                options={this.state.countries}
                                                                onChange={(e, v, r, d) => {
                                                                    // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                    if (r == 'selectOption') {
                                                                        this.setState({ selectedCountry: v.label })
                                                                        this.onGetStateList(v.Code)
                                                                        //this.onSelectCountry(v.label)
                                                                    } else if (r == 'clear') {
                                                                        this.setState({ selectedState: "" })
                                                                        this.setState({ selectedDistrict: "" })
                                                                        this.setState({ selectedCity: "" })
                                                                        this.setState({ selectedCountry: "" })
                                                                    }
                                                                }}

                                                                renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                            />



                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>State / રાજ્ય:</label>
                                                            <Autocomplete
                                                                disablePortal
                                                                disabled={this.state.stateList.length > 0 ? false : true}
                                                                id="combo-box-demo"
                                                                value={this.state.selectedState}
                                                                options={this.state.stateList}
                                                                onChange={(e, v, r, d) => {
                                                                    // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                    if (r == 'selectOption') {
                                                                        this.setState({ selectedState: v.label })
                                                                        this.onGetDistrictList(v.Code)

                                                                    } else if (r == 'clear') {
                                                                        this.setState({ selectedDistrict: "" })
                                                                        this.setState({ selectedCity: "" })
                                                                        this.setState({ selectedState: "" })
                                                                    }
                                                                }}

                                                                renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                            />



                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>District / જિલ્લો:</label>
                                                            <Autocomplete
                                                                disablePortal
                                                                disabled={this.state.districtList.length > 0 ? false : true}
                                                                id="combo-box-demo"
                                                                value={this.state.selectedDistrict}
                                                                options={this.state.districtList}
                                                                onChange={(e, v, r, d) => {
                                                                    // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                    if (r == 'selectOption') {
                                                                        this.setState({ selectedDistrict: v.label })
                                                                        this.onGetCitiesList(v.Code)
                                                                        //this.onSelectCountry(v.label)
                                                                    } else if (r == 'clear') {
                                                                        this.setState({ selectedCity: "" })
                                                                        this.setState({ selectedDistrict: "" })
                                                                    }
                                                                }}

                                                                renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                            />



                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>City / શહેર:</label>
                                                            <Autocomplete
                                                                disablePortal
                                                                disabled={this.state.CityList.length > 0 ? false : true}
                                                                id="combo-box-demo"
                                                                options={this.state.CityList}
                                                                value={this.state.selectedCity}
                                                                onChange={(e, v, r, d) => {
                                                                    // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                    if (r == 'selectOption') {
                                                                        this.setState({ selectedCity: v.label, cityCode: v.Code })
                                                                        // this.onGetVillageList(v.Code)
                                                                        //this.onSelectCountry(v.label)
                                                                    } else if (r == 'clear') {

                                                                        this.setState({ selectedCity: "" })
                                                                    }
                                                                }}

                                                                renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                            />
                                                            <span className="form-text text-danger">{this.state.errors?.selectedCity}</span>



                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>

                                        <div className="card-footer" style={{ justifyContent: 'center', alignItems: 'center' }}>

                                            <button type="button" className="btn btn-primary mb-2" onClick={(event) => this.onUserInvite(event)}>

                                                {this.state._id == '' || this.state._id == null || this.state._id == undefined ?
                                                    <label> Add Location</label> :
                                                    <label>Edit Location</label>
                                                }
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        );
    }
}

export default withRouter(LocationAdd);
