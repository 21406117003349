import withRouter from '../Utilities/withRouter'
import React, { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Html5QrcodeScanner } from 'html5-qrcode';
import Constants from '../Utilities/Constants';
import { Dots } from 'react-activity';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import moment from 'moment';

const EventAttend = (props) => {
    const { id } = props.params;
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [isValidMobileNo, setIsValidMobileNo] = useState(true);
    const [eventList, setEventList] = useState([]);
    const [eventUser, setEventUser] = useState(null);
    // const [event, setEvent] = useState("");
    const [eventId, setEventID] = useState("")
    const [userId, setUserID] = useState(null);
    const [mode, setMode] = useState(null);
    const [event, setEvent] = useState(null);
    const [locationDetails, setlocationDetails] = useState(null);
    const [countries, setcountries] = useState([]);
    const [stateList, setstateList] = useState([]);
    const [districtList, setdistrictList] = useState([]);
    const [CityList, setCityList] = useState([]);

    const [selectedCity, setselectedCity] = useState(null);
    const [selectedCountry, setselectedCountry] = useState(null);
    const [selectedDistrict, setselectedDistrict] = useState(null);
    const [selectedState, setselectedState] = useState(null);
    const [attendedBoyChilds, setattendedBoyChilds] = useState(null);
    const [attendedGirlChilds, setattendedGirlChilds] = useState(null);
    const [attendedManAdults, setattendedManAdults] = useState(null);
    const [attendedWomanAdults, setattendedWomanAdults] = useState(null);
    const [IsRegistrationOpen, setIsRegistrationOpen] = useState(true);
    const [street_address,setstreet_address]=useState(null)
    const [areas,setareas]=useState([])

    useEffect(() => {

        // console.log(props)
        // const { id } = props.params;

        setEventID(id);
        const scanner = new Html5QrcodeScanner('reader', {
            qrbox: {
                width: 250,
                height: 250,
            },
            rememberLastUsedCamera: true,
            disableFlip: true,
            fps: 5,
        });

        scanner.render(success, error)
        //   setLoading(false)
        // let heading = {
        //     title: 'Event',
        //     subTitle: ['Attend Event']
        // };
        // localStorage.setItem('heading', JSON.stringify(heading));

        function success(result) {
            scanner.clear();
            setResult(result)
            setLoading(true);
            onScanned("QR", result)

        }
        function error(err) {
            // console.log(err)
        }
        function getEvent() {
            let urlPath = "event/eventdetails/" + id

            fetch(Constants.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then((response) => response.json())
                .then((responseData) => {

                    if (responseData.status) {
                        var datares = responseData.data

                        setEvent(datares)
                        setlocationDetails(responseData.location)
                        var current = new Date();
                        var RegstartD = new Date(datares.registrationStartDate);
                        var RegendD = new Date(datares.registrationEndDate);
                        if (RegstartD <= current && RegendD >= current) {
                            setIsRegistrationOpen(true)
                        } else {
                            setIsRegistrationOpen(false)
                        }

                        // eventName= datares.eventName
                        // eventStartDate= new Date(datares.eventStartDate)
                        // eventEndDate= new Date(datares.eventEndDate)
                        // registrationStartDate= new Date(datares.registrationStartDate)
                        // registrationEndDate= new Date(datares.registrationEndDate)

                    } else {
                        alert(
                            'Something went wrong, Please try again after sometime.'
                        )
                        // this.setState({ loading: false });
                    }
                }).catch(error => {
                    console.log("err", error);
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    // this.setState({ loading: false });
                }).finally(final => {
                    // return this.setState({ isInvitingUser: false })
                });

        }
        getEvent()

    }, []);


    function scanAgain() {
        window.location.reload();
    }

    function Register() {
        // console.log("CALLING")

        if (eventId != "") {
            setResult("success")
            setLoading(true)
            const Body = JSON.stringify({
                "mode": mode,
                "mobileNo": mobileNo,
                "userId": userId,
                eventId: eventId,
                "attendedCot": eventUser.attendedCot,
                "BoyChilds": eventUser.BoyChilds,
                "GirlChilds": eventUser.GirlChilds,
                "ManAdults": eventUser.ManAdults,
                "WomanAdults": eventUser.WomanAdults,

                "attendedBoyChilds": (parseInt(eventUser.attendedBoyChilds) + parseInt(attendedBoyChilds)).toString(),
                "attendedGirlChilds": (parseInt(eventUser.attendedGirlChilds) + parseInt(attendedGirlChilds)).toString(),
                "attendedManAdults": (parseInt(eventUser.attendedManAdults) + parseInt(attendedManAdults)).toString(),
                "attendedWomanAdults": (parseInt(eventUser.attendedWomanAdults) + parseInt(attendedWomanAdults)).toString(),
                'client': Constants.AUTH_APP_NAME,
                'client_secret': Constants.AUTH_SECRET,
            })

            let urlPath = "event/register-user-for-event/";


            fetch(Constants.VUF_APP_API + urlPath, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: Body,
            }).then((response) => response.json())
                .then((responseData) => {
                    console.log(responseData);
                    setTimeout(() => {
                        setLoading(false)
                    }, 1000)
                    if (responseData.status) {
                        alert("Added Successfully!")
                        setShowMessage("Added Successfully!");

                        scanAgain()


                    } else if (responseData.error && responseData.error && typeof responseData.error != "object") {
                        alert(
                            responseData.error)
                        setLoading(false)
                        setShowMessage(responseData.error)
                    } else if (typeof responseData.error === "object") {
                        alert(
                            'Error!  \n\n ' + responseData.error.length > 0 ? responseData.error[0].message : 'Something went wrong, Please try again after sometime.'
                        )
                        setShowMessage(responseData.error.length > 0 ? responseData.error[0].message : 'Something went wrong, Please try again after sometime.')
                        setLoading(false)
                    } else {
                        alert(
                            'Oops! \n\n Something went wrong, Please try again after sometime.'
                        )
                        setShowMessage("Sorry, User not registered. Please try again.")
                        setLoading(false)
                    }

                }).catch(error => {
                    // // console.log("err", error);
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    setShowMessage("Sorry, User not registered. Please try again.")
                    setLoading(false)
                }).finally(final => {

                });
        } else {
            alert("Please select Event.");
            setShowMessage("Please select Event.")
        }
    }

    const handleMobileNoChange = (event) => {
        const newMobileNo = event.target.value;

        // Regular expression for exactly 10 numeric characters
        const numericRegex = /^[0-9]{0,10}$/;

        // Validate the input against the regular expression
        const isValid = numericRegex.test(newMobileNo);

        // Update the isValidMobileNo state based on the validation result
        setIsValidMobileNo(isValid);

        // If the input is valid or empty, and the length is less than or equal to 10, update the mobileNo state
        if ((isValid || newMobileNo === '') && newMobileNo.length <= 10) {
            setMobileNo(newMobileNo);
        }
    };

    const checkMobileNo = () => {
        console.log("M ", mobileNo.length)
        if (mobileNo.length < 10) {
            setIsValidMobileNo(false)
        } else {
            setIsValidMobileNo(true)
        }
    }

    async function onScanned(mode, scanndata = null) {
        console.log(mode)
        console.log(scanndata)
        var userId = null;
        var EventId = null;
        setMode(mode)
        if (scanndata != null) {
            userId = scanndata.split("_")[0];
            EventId = scanndata.split("_")[1];
            setEventID(EventId)
            setUserID(userId)
        } else {
            EventId = eventId;
            setEventID(EventId)
        }

        if (EventId != "") {
            setResult("success")
            setLoading(true)
            const Body = JSON.stringify({
                "mode": mode,
                "mobileNo": mobileNo,
                "userId": userId,
                eventId: EventId,
                'client': Constants.AUTH_APP_NAME,
                'client_secret': Constants.AUTH_SECRET,
            })

            let urlPath = "event/get-user-for-event";


            fetch(Constants.VUF_APP_API + urlPath, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: Body,
            }).then((response) => response.json())
                .then((responseData) => {
                    console.log(responseData);
                    setTimeout(() => {
                        setLoading(false)
                    }, 1000)
                    if (responseData.status || responseData.response) {
                        var resdata = responseData.response;
                        setattendedBoyChilds((parseInt(resdata.attendedBoyChilds) || 0).toString());
                        setattendedGirlChilds((parseInt(resdata.attendedGirlChilds) || 0).toString());
                        setattendedManAdults((parseInt(resdata.attendedManAdults) || 0).toString());
                        setattendedWomanAdults((parseInt(resdata.attendedWomanAdults) || 0).toString());

                        resdata.attendedBoyChilds = Math.max((parseInt(resdata.BoyChilds) || 0) - (parseInt(resdata.attendedBoyChilds) || 0), 0);
                        resdata.attendedGirlChilds = Math.max((parseInt(resdata.GirlChilds) || 0) - (parseInt(resdata.attendedGirlChilds) || 0), 0);
                        resdata.attendedManAdults = Math.max((parseInt(resdata.ManAdults) || 0) - (parseInt(resdata.attendedManAdults) || 0), 0);
                        resdata.attendedWomanAdults = Math.max((parseInt(resdata.WomanAdults) || 0) - (parseInt(resdata.attendedWomanAdults) || 0), 0);

                        setEventUser(resdata)
                        setselectedCity(resdata.userId.city);
                        setselectedCountry(resdata.userId.country);
                        setselectedDistrict(resdata.userId.district);
                        setselectedState(resdata.userId.state);

                        if(resdata.userId.Pincode!=""){
                            onChangePostcode(resdata.userId.Pincode);
                        }
                        //onGetCountryList()

                        if (responseData.error && responseData.error && typeof responseData.error != "object") {
                            alert(
                                responseData.error)
                            setLoading(false)
                            setShowMessage(responseData.error)
                        }

                    } else if (responseData.error && responseData.error && typeof responseData.error != "object") {
                        alert(
                            responseData.error)
                        setLoading(false)
                        setShowMessage(responseData.error)
                    }

                }).catch(error => {
                    // // console.log("err", error);
                    setLoading(false)
                }).finally(final => {

                });
        } else {
            alert("Please select Event.");
            setShowMessage("Please select Event.")
        }

    }
    async function onGetCountryList() {
        let urlPath = "auth/CountryList"

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;

                    let countriesdata = []

                    data.map((c, i) => {
                        countriesdata.push({ label: c.country_name, id: c._id, Code: c.id })
                    })

                    setcountries(countriesdata);

                    if (selectedCountry != '' && selectedCountry != null) {
                        let stateCode = countries.filter(s => s.label == selectedCountry)[0].Code
                        onGetStateList(stateCode);
                    } else {
                        selectedCity = ""
                        selectedDistrict = ""
                        selectedState = ""
                    }
                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {

            });
    }
    function onGetStateList(data) {
        if (data != null && data != '') {

            let urlPath = "auth/StatesList/" + data

            fetch(Constants.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body: Body,
            }).then((response) => response.json())
                .then(async (responseData) => {
                    // // // console.log("RES ",responseData)
                    if (responseData.status) {
                        var resp = responseData.response;

                        let stateListdata = []

                        resp.map((c, i) => {
                            stateListdata.push({ label: c.state_title, id: c._id, Code: c.state_id })
                        })
                        setstateList(stateListdata)

                        if (selectedState != '' && selectedState != null) {
                            let stateCode = stateList.filter(s => s.label == selectedState)[0].Code
                            onGetDistrictList(stateCode);
                        } else {
                            selectedState = ""
                            selectedDistrict = ""
                            selectedCity = ""

                        }

                    }
                }).catch(error => {
                    // // console.log("err", error);

                }).finally(final => {
                    //return this.setState({ loading: false })
                });
        }
    }
    function onGetDistrictList(data) {

        let urlPath = "auth/DistrictsList/" + data

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var datares = responseData.response;
                    let districtListdata = []

                    datares.map((c, i) => {
                        districtListdata.push({ label: c.district_title, id: c._id, Code: c.districtid })
                    })

                    setdistrictList(districtListdata)

                    if (selectedDistrict != '' && selectedDistrict != null) {
                        let districtCode = districtList.filter(s => s.label == selectedDistrict)[0].Code
                        onGetCitiesList(districtCode);
                    } else {
                        selectedDistrict = ""
                        selectedCity = ""
                    }


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                // return this.setState({ loading: false })
            });
    }
    function onGetCitiesList(data) {
        let urlPath = "auth/CitiesList/" + data

        fetch(Constants.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var datares = responseData.response;
                    let CityListdata = []

                    datares.map((c, i) => {
                        CityListdata.push({ label: c.name, id: c._id, Code: c.id })
                    })

                    setCityList(CityListdata);


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                //return this.setState({ loading: false })
            });
    }
    function onChangePostcode(e){
        console.log(e)
        //let urlPath = "https://api.postalpincode.in/pincode/" + e;

        if (e != null && e != "") {
            let urlPath = "auth/PostalCode/" + e;

            fetch(Constants.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then((response) => response.json())
                .then((responseData) => {
                    console.log(responseData);

                    if (responseData.status) {

                        if (responseData.response[0].PostOffice != null && responseData.response[0].PostOffice.length > 0) {
                            var data = responseData.response[0].PostOffice[0];
                           
                            setstreet_address(data.Country +", "+ data.State+", "+ data.District+", "+ data.Block)        
                            //this.onGetCountryList();
                        }
                    } 

                 

                }).catch(error => {
                    console.log("err", error);
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                   
                });
        } else {
            
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEventUser((prevState) => ({
            ...prevState,
            [name]: value, // Dynamically update the corresponding input field
        }));
    };
    let divImage =  "../../assets/images/VUF_temple.jpg";
    // console.log(this.state.loading)
    const formatDate = (stdate) => {
        if(stdate!=null){
        const date = new Date(stdate); // 8th September 2024 (Month is 0-based, so 8 represents September)
        const options = { day: "numeric", month: "long" };
        return date.toLocaleDateString("en-GB", options); // "8 September"
    }
      };
    //   loading
    //   ?
    //   <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
    //   :


    return (
        <>
            <div>

                {/* Header with Logo and Event Title */}
                <header className="header">
                    <img src="../../assets/images/vuflogo.png" alt="Vishv Umiya Logo" className="logo" />
                    <h1>Event Registration</h1>
                </header>

                {/* Banner with Background Image and Event Details */}
                <div className="banner" style={{ backgroundImage: 'url(' + divImage + ')' }}>
                    {event!=null &&
                        <div className=''>
                            <h2>{event.eventName}</h2>
                            <p>{formatDate(event.eventStartDate)}
                                {/* to {moment(this.state.Eventdetails.eventEndDate).format("DD MMM YYYY")}  */}
                            </p>
                            <p>Venue: {event != null && event.Address != null ? <span> {event.Address} </span> : <span> Country - {event.country}. State - {event.state}. City - {event.city} </span>}
                                {/* {this.state.locationDetails != null && <span >{this.state.locationDetails.name}</span>}  */}
                            </p>
                        </div>
                    }

                </div>
                {/* <div  style={{ backgroundImage: `url("../assets/images/vuf-border.png") `,backgroundRepeat:'repeat' }}>
</div> */}
                <div class="temple-border"></div>
                {/* <img src="../assets/images/vuf-border.png" style={{backgroundRepeat:'repeat-x',backgroundSize:'auto 40px'}} alt="border Image" class="temple-border" /> */}
                {/* Registration Form */}
                <div className="container">
                    {IsRegistrationOpen == true ?
                        <div className="card-body row" style={{ paddingTop: 10 }}>




                            <div className='col-md-12 border-right-desktop-deprecated'>

                                {/* <div className='col-md-12' style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <label style={{ textAlign: 'center', width: '100%', fontSize: 23, fontWeight: '700', color: '#bc2f30', marginBottom: 10 }}>VUF Event Registration</label>
                                        </div> */}




                            </div>

                            {loading ?
                                <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
                                :
                                <>
                                    {!result && <div className='col-md-12 ' >
                                        <div className='col-md-12 row'>
                                            {/* <div className="col-md-4 " style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 0 }}>
                                                        <label>Event:</label>
                                                        <select value={event} className="form-control form-select-solid" onChange={(event) => setEvent(event.target.value)} aria-label="Select Event"  >
                                                            <option value="">Select Event</option>
                                                            {
                                                                eventList.map((eve) => {
                                                                    return (<option value={eve._id}>{eve.eventName}</option>)
                                                                })
                                                            }
                                                        </select>

                                                    </div> */}
                                            <div className="col-md-8 ">
                                                <label>Mobile Number / મોબાઇલ નંબર:</label>
                                                <div className='row'>
                                                    <div className='col-md-2'>
                                                        <input type="text" name="ccode" disabled className="form-control form-control-solid" placeholder="+91" value="+91" />
                                                    </div>
                                                    <div className='col-md-10'>
                                                        <input type="text" name="mobileNo" className="form-control form-control-solid" placeholder="Mobile number" onBlur={checkMobileNo} onChange={handleMobileNoChange} value={mobileNo} />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='col-md-12 text-center' style={{ paddingTop: 20, marginBottom: 0 }}>
                                            <div className='col-md-21 col-sm-12 col-sm-12 margin-top-mob' >
                                                <div className=" text-center">
                                                    <button type="button" disabled={!isValidMobileNo} className="btn btn-primary mr-2" onClick={(event) => onScanned("mobile")}>User for event</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>}


                                    <div className='col-md-12 text-center'>

                                        {
                                            result ?
                                                <>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <label style={{ textAlign: 'center', width: '100%', fontSize: 18, fontWeight: '700', color: '#363535', marginTop: 20, marginBottom: 20, }}>{showMessage}</label>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <button type="button" className="btn btn-primary mr-2" onClick={(event) => scanAgain()}>Scan New</button>
                                                        </div>
                                                    </div>

                                                </>
                                                :
                                                <div style={{ flex: 1 }} id="reader"></div>
                                        }


                                    </div>
                                    {eventUser != null &&
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-12 col-lg-6'>

                                                        <label>Mobile Number / મોબાઇલ નંબર:</label>
                                                        <div className='row'>
                                                            <div className='col-12 col-lg-2'>
                                                                <input type="text" name="ccode" className="form-control form-control-solid" placeholder="+91" value={eventUser.userId.ccode} />

                                                            </div>
                                                            <div className='col-12 col-lg-10'>
                                                                <input type="text" disabled name="mobileNo" className="form-control form-control-solid" placeholder="Mobile number" value={eventUser.userId.mobileNo} />

                                                            </div>
                                                    
                                                    </div>
                                                </div>


                                            </div>

                                            <div className='row'>
                                                <div className='col-12 col-lg-6'>
                                                   
                                                        <label>First Name / નામ:</label>
                                                        <input type="text" name="firstName" disabled="true" className="form-control form-control-solid" placeholder="Enter first name" value={eventUser.userId.firstName} />

                                                </div>

                                                <div className="col-12 col-lg-6">
                                                   
                                                        <label>Last Name / અટક:</label>
                                                        <input type="text" name="lastName" disabled="true" className="form-control form-control-solid" placeholder="Enter last name" value={eventUser.userId.lastName} />

                                                </div>

                                            </div>

                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label>Pincode / પિનકોડ: </label>
                                                    <input type="text" name="Pincode" disabled="true" 
                                                     className="form-control form-control-solid" placeholder="Pincode"  value={eventUser.userId.Pincode} />
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Country / દેશ, State / રાજ્ય, District / જિલ્લો, City / શહેર </label>
                                                    
                                                    <input type="text" name="street_address" disabled="true" className="form-control form-control-solid"  value={street_address} />
                                                    {/* <Autocomplete
                                                        disablePortal
                                                        disabled={this.state.enableInput && this.state.countries.length > 0 ? false : true}
                                                        id="combo-box-demo"
                                                        value={this.state.selectedCountry}
                                                        options={this.state.countries}
                                                        onChange={(e, v, r, d) => {
                                                            // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                            if (r == 'selectOption') {
                                                                this.setState({ selectedCountry: v.label, country: v.label })
                                                                this.onGetStateList(v.Code)
                                                                //this.onSelectCountry(v.label)
                                                            } else if (r == 'clear') {
                                                                this.setState({ selectedState: "" })
                                                                this.setState({ selectedDistrict: "" })
                                                                this.setState({ selectedCity: "" })
                                                                this.setState({ selectedCountry: "" })
                                                            }
                                                        }}

                                                        renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                    /> */}
                                                </div>
                                                <div className="col-md-2">
                                                <label>Area / વિસ્તાર (Optional): </label>
                                                    <Autocomplete
                                                        disablePortal
                                                        disabled="true"
                                                        id="combo-box-demo"
                                                        value={eventUser.userId.area}
                                                        options={areas}

                                                        renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                    />
                                                </div>
                                              
                                            </div>
                                            <div className='row'>
                                                 <div className='col-md-6'>
                                                    <label>Address / સરનામું (Optional):</label>
                                                    <textarea disabled="true" type="text" name="addressComponent" className="form-control form-control-solid" placeholder="Enter Address" >
                                                        {eventUser.userId.addressComponent}
                                                    </textarea>
                                                </div>

                                            </div>
                                            <div className='row'style={{display:'none'}}>
                                                <div className="col-6 col-lg-3">
                                                        <label>Country / દેશ:</label>
                                                        <Autocomplete
                                                            disablePortal
                                                            disabled="true"
                                                            id="combo-box-demo"
                                                            value={selectedCountry}
                                                            options={countries}
                                                            renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                        />


                                                </div>

                                                <div className="col-6 col-lg-3">
                                                   
                                                        <label>State / રાજ્ય:</label>
                                                        <Autocomplete
                                                            disablePortal
                                                            disabled="true"
                                                            id="combo-box-demo"
                                                            value={selectedState}
                                                            options={stateList}
                                                            renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                        />

                                                </div>
                                                <div className="col-6 col-lg-3">
                                                   
                                                        <label>District / જિલ્લો:</label>
                                                        <Autocomplete
                                                            disablePortal
                                                            disabled="true"
                                                            id="combo-box-demo"
                                                            value={selectedDistrict}
                                                            options={districtList}
                                                            renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                        />


                                                </div>
                                                <div className="col-6 col-lg-3">
                                                   
                                                        <label>City / શહેર:</label>
                                                        <Autocomplete
                                                            disablePortal
                                                            disabled="true"
                                                            id="combo-box-demo"
                                                            options={CityList}
                                                            value={selectedCity}


                                                            renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                        />

                                                </div>


                                            </div>
                                            <div className='row' style={{display:'none'}}>
                                                <div className='col-md-12 '>
                                                    <b className=''>Registered Member(s)</b>
                                                    <div className='row'>
                                                        <div className='col-6 col-lg-3 '> <label className='mt-3 '>Man Adult:</label><br />  <input type="number" min="0" max="10" name="ManAdults" className="form-control " onChange={handleInputChange} value={eventUser.ManAdults} /></div>
                                                        <div className='col-6 col-lg-3 '> <label className='mt-3 '>Woman Adult:</label><br /> <input type="number" min="0" max="10" name="WomanAdults" className="form-control " onChange={handleInputChange} value={eventUser.WomanAdults} /></div>
                                                        <div className='col-6 col-lg-3 '>  <label className='mt-3'>Boy Child:</label><br />  <input type="number" min="0" max="10" name="BoyChilds" className="form-control " onChange={handleInputChange} value={eventUser.BoyChilds} /></div>
                                                        <div className='col-6 col-lg-3 '><label className='mt-3 '>Girl Child:</label><br />  <input type="number" min="0" max="10" name="GirlChilds" className="form-control " onChange={handleInputChange} value={eventUser.GirlChilds} /></div>

                                                    </div>
                                                    <br />
                                                    <b className=' mt-2' >Attendent Member(s)</b>
                                                    <div className='row'>
                                                        <div className='col-6 col-lg-3 '> <label className='mt-3 '>Man Adult ({attendedManAdults}):</label><br />  <input type="number" min="0" max="10" name="attendedManAdults" className="form-control " onChange={handleInputChange} value={eventUser.attendedManAdults} /></div>
                                                        <div className='col-6 col-lg-3'> <label className='mt-3 '>Woman Adult ({attendedWomanAdults}):</label><br /> <input type="number" min="0" max="10" name="attendedWomanAdults" className="form-control " onChange={handleInputChange} value={eventUser.attendedWomanAdults} /></div>
                                                        <div className='col-6 col-lg-3 '>  <label className='mt-3'>Boy Child ({attendedBoyChilds}):</label><br />  <input type="number" min="0" max="10" name="attendedBoyChilds" className="form-control " onChange={handleInputChange} value={eventUser.attendedBoyChilds} /></div>
                                                        <div className='col-6 col-lg-3 '><label className='mt-3 '>Girl Child ({attendedGirlChilds}):</label><br />  <input type="number" min="0" max="10" name="attendedGirlChilds" className="form-control " onChange={handleInputChange} value={eventUser.attendedGirlChilds} /></div>

                                                    </div>
                                                </div>

                                            </div>
                                            {showMessage == "" &&
                                                <div className='col-md-12 text-center' style={{ paddingTop: 20, marginBottom: 0 }}>
                                                    <div className='col-md-21 col-sm-12 col-sm-12 margin-top-mob' >
                                                        <div className=" text-center">
                                                            <button type="button" className="btn btn-primary mr-2" onClick={(event) => Register()}>User Attendence for event</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </>

                            }



                        </div>
                        :
                        <div className="card-body row">

                            <label>Registration Close</label>

                        </div>
                    }

                </div>

                {/* Footer with Red Background and Footer Image */}
                {/* <div className='temple-image'>
    <img src="../assets/images/temple.jpg" alt="Temple Image" class="" />
</div> */}
                <div style={{ marginLeft: "80%" }}>
                    <img src="../../assets/images/temple.jpg" alt="Temple Image" class="temple-image" />
                </div>
                <div class="footer">

                    <p class="iso">ISO 9001:2015 Approval Number: 00044220</p>
                    <p class="copyright">Copyright © 2024 Vishv Umiya Foundation. All Rights Reserved.</p>
                </div>


            </div>
        </>
    );
};



export default withRouter(EventAttend);
